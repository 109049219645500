import React, {useCallback, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {useFarmTitle} from "states";
import {getAnimalsResearch} from "api/rest";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {BasicTable, FarmNavigationButtonGroup} from "components";

export function AnimalsResearch() {
    const {id} = useParams();
    const {farmTitle} = useFarmTitle();

    const [message, setMessage] = useState("");
    const [dataset, setDataset] = useState([]);

    const submitRequestDataset = useCallback((id) => {
        setMessage("");
        getAnimalsResearch(id).then((data) => setDataset(data)).catch((error) => {
            if (error.response.data !== "" && error.response.data.hasOwnProperty("message"))
                setMessage(error.response.data.message);
            else console.log(error.toString());
        });
    }, []);

    useEffect(() => submitRequestDataset(id), [id, submitRequestDataset]);

    return (
        <div className="shadow">
            <Row className="justify-content-between pt-3 pl-3 pr-3">
                <Col md="auto">
                    <h4>{farmTitle}</h4>
                </Col>
                <Col md="auto">
                    <FarmNavigationButtonGroup id={id}/>
                </Col>
            </Row>
            <Row className="pl-3 pr-3 pb-3">
                <Col md="auto">
                    <h4>Animals Research</h4>
                </Col>
            </Row>
            <Row className="pl-3 pr-3 pb-3">
                <Col>
                    {dataset.length !== 0 && <ResearchTable dataset={dataset}/>}
                </Col>
            </Row>
            <Row className="justify-content-center">
                <Col md="auto">
                    <b>{message}</b>
                </Col>
            </Row>
        </div>
    );
}

function ResearchTable({dataset}) {
    const columns = React.useMemo(() => {
        const columns = [
            {Header: "Parity", accessor: "parity"},
            {Header: "Since start data", accessor: "count"},
            {Header: "At farm", accessor: "at_farm"},
        ];

        const departureReasonAccessors = Object.keys(dataset[0])
            .filter((c) => c.startsWith("departure:"))
            .sort();
        const departureColumns = departureReasonAccessors.map((accessor) => (
            {
                Header: accessor.split(":")[1],
                accessor: row => row[accessor] !== 0 ? row[accessor] : ""
            }));

        columns.push({Header: "Departure reason", columns: departureColumns});

        return columns;
    }, [dataset]);

    return (
        <BasicTable columns={columns} data={dataset}/>
    );
}