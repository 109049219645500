import React from "react";
import {ErrorMessage, useField} from "formik";
import Datetime from "react-datetime";

function FormikDatetime({label, initialValue, ...props}) {
    // eslint-disable-next-line
    const [field, meta, helpers] = useField(props);

    const {setValue} = helpers;

    return (
        <div>
            <label htmlFor={props.id || props.name}>{label}</label>
            <Datetime
                onChange={(datetime) => {
                    if (typeof datetime === "string") setValue(null);
                    else setValue(datetime.format("YYYY-MM-DDTHH:mm:ss"))
                }}
                initialValue={initialValue}
                closeOnSelect={true}
                dateFormat="DD-MM-YYYY"
                timeFormat=""
            />
            <ErrorMessage name={field.name} component="span" className="text-danger"/>
        </div>
    );
}

export default FormikDatetime;