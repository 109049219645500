import Form from "react-bootstrap/Form";
import React from "react";
import {useBlockLayout, useFilters, useSortBy, useTable} from "react-table";
import {DefaultColumnFilter} from "components/TableFilters";
import {advancedFilter} from "utils/utils";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Button from "react-bootstrap/Button";
import Popover from "react-bootstrap/Popover";

export function HideColumnOverlay({id, columns}) {
    const popover = (
        <Popover id={id}>
            <Popover.Content>
                {columns.map(c => <Form.Check key={c.id} id={c.id} label={c.render("Header")}
                                              type="checkbox" {...c.getToggleHiddenProps()}/>)}
            </Popover.Content>
        </Popover>
    );

    return (
        <OverlayTrigger trigger="click" rootClose placement="bottom" overlay={popover}>
            <Button size="sm" variant="outline-primary">Columns</Button>
        </OverlayTrigger>
    );
}

export const useSortableFilterableTable = (columns, data,
                                           defaultColumn = {Filter: DefaultColumnFilter}) => {
    const filterTypes = React.useMemo(() => ({
        advanced: (rows, id, filterValue) => {
            return rows.filter((row) => {
                const rowValue = row.values[id];
                return rowValue !== undefined ? advancedFilter(filterValue, String(rowValue).toLowerCase()) : false;
            });
        }
    }), []);

    const initialState = React.useMemo(() => ({
        hiddenColumns: columns.filter(column => column.show === false).map(column => column.accessor || columns.id)
    }), [columns]);

    return useTable({
        columns,
        data,
        defaultColumn,
        initialState,
        filterTypes
    }, useFilters, useSortBy, useBlockLayout);
}