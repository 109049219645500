import React, {useEffect, useMemo, useState} from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {SelectInput} from "components";
import {groupBy} from "utils/utils";
import FromToInput from "./FromToInput";
import Form from "react-bootstrap/Form";
import {DoubleYAxisLineTimeChart} from "components/LineTimeChart";
import {startCase} from "lodash";
import moment from "moment";

const mean = (dataset, column) => {
    let n = 0;
    let sum = 0;
    dataset.forEach((row) => {
        if (row[column] !== null) {
            n++;
            sum += row[column];
        }
    });

    return sum / n;
};

const doAggregation = (method, column, obj) => {
    if (method === "mean") return mean(obj, column);

    return 0;
};

const multipleSelectLimit = 15;

function InputSide({dataset}) {
    const [firstYAxis, setFirstYAxis] = useState("milk revenue");
    const [secondYAxis, setSecondYAxis] = useState("melkgift");
    const [aggregationMethod, setAggregationMethod] = useState("mean");
    const [filteredDataset, setFilteredDataset] = useState([]);
    const [extraFilterInput, setExtraFilterInput] = useState({});
    const [startFromZeroFirstY, setStartFromZeroFirstY] = useState(false);
    const [startFromZeroSecondY, setStartFromZeroSecondY] = useState(false);

    const filterableColumnData = useMemo(() =>
        dataset.length === 0 ? {} :
            Object.keys(dataset[0]).reduce((result, column) => {
                const uniqueValues = [...new Set(
                    dataset.filter((row) => row[column] !== null).map((row) => row[column])
                )];
                result[column] = {min: Math.min(...uniqueValues), max: Math.max(...uniqueValues)};

                // Sort only if it will be a multiple select
                if (uniqueValues.length <= multipleSelectLimit) {
                    if (!isNaN(result[column].min) && !isNaN(result[column].max))
                        uniqueValues.sort((a, b) => a - b);
                    else uniqueValues.sort()
                }

                result[column].values = uniqueValues.map((value) => ({value: value, label: value.toString()}))

                return result;
            }, {}), [dataset]);
    const numericalColumnOptions = Object.entries(filterableColumnData)
        .filter((entry) => entry[1].values.length > 1 && !isNaN(entry[1].min) && !isNaN(entry[1].max))
        .map((entry) => ({value: entry[0], label: entry[0]}));
    const aggregationOptions = [
        {value: "mean", label: "mean"},
        // {value: "median", label: "median"},
        // {value: "10th percentile", label: "10th percentile"},
        // {value: "90th percentile", label: "90th percentile"},
    ];

    useEffect(() => {
        if (firstYAxis === "" || aggregationMethod === "") return;

        let newDataset = dataset;
        if (extraFilterInput.length !== 0)
            newDataset = newDataset.filter((row) =>
                Object.entries(extraFilterInput).map((entry) => {
                    const column = entry[0];
                    const options = entry[1];
                    if (options.hasOwnProperty("selected"))
                        return options["selected"].map((option) => option.value).includes(row[column]);
                    else if (options.hasOwnProperty("from"))
                        return row[column] >= options["fromValue"] && row[column] <= options["toValue"];
                    else return true;
                }).every((val) => val === true));

        const groups = groupBy(newDataset, "monster_datum");
        newDataset = [];
        groups.forEach((group) => {
            const row = {monster_datum: group[0]["monster_datum"]};
            row[firstYAxis] = doAggregation(aggregationMethod, firstYAxis, group);

            if (secondYAxis !== "") row[secondYAxis] = doAggregation(aggregationMethod, secondYAxis, group);

            newDataset.push(row);
        });
        setFilteredDataset(newDataset);
    }, [dataset, firstYAxis, secondYAxis, aggregationMethod, extraFilterInput]);

    const createMultiSelectFilter = (column, options) => {
        return (
            <SelectInput
                key={column} initialValue={options.selected} label={column}
                options={filterableColumnData[column].values} isMultiple={true}
                handleChange={(selectedOptions) => {
                    extraFilterInput[column].selected = selectedOptions;
                    setExtraFilterInput({...extraFilterInput});
                }}
            />
        );
    };
    const createFromToFilter = (column, options) => {
        return (
            <FromToInput
                key={column} label={column} min={options.from} max={options.to}
                fromValue={options.fromValue} toValue={options.toValue}
                handleFromChange={(fromValue) => {
                    if (isNaN(fromValue) || fromValue < options.from || fromValue > options.to) return;

                    extraFilterInput[column].fromValue = fromValue;
                    setExtraFilterInput({...extraFilterInput});
                }}
                handleToChange={(toValue) => {
                    if (isNaN(toValue) || toValue < options.from || toValue > options.to) return;

                    extraFilterInput[column].toValue = toValue;
                    setExtraFilterInput({...extraFilterInput});
                }}
            />
        )
    };
    const createFilterComponent = (column, options) => {
        if (options.hasOwnProperty("selected")) return createMultiSelectFilter(column, options);
        else if (options.hasOwnProperty("from")) return createFromToFilter(column, options);
    };

    return (
        <Row className="pl-3 pr-3 pb-3"
             style={firstYAxis !== "" && aggregationMethod !== "" ? {minHeight: "65vh"} : {}}>
            <Col md={4}>
                <SelectInput
                    initialValue={[firstYAxis]} label="Y-axis 1" options={numericalColumnOptions}
                    handleChange={(selectedOptions) => {
                        if (selectedOptions.length === 0) setFirstYAxis("");
                        else setFirstYAxis(selectedOptions[0].value);
                    }}
                />
                <Form.Group>
                    <Form.Check label="Start from 0" type="checkbox"
                                onChange={() => setStartFromZeroFirstY(!startFromZeroFirstY)}/>
                </Form.Group>
                <SelectInput
                    initialValue={[secondYAxis]} label="Y-axis 2" options={numericalColumnOptions}
                    handleChange={(selectedOptions) => {
                        if (selectedOptions.length === 0) setSecondYAxis("");
                        else setSecondYAxis(selectedOptions[0].value);
                    }}
                />
                <Form.Group>
                    <Form.Check label="Start from 0" type="checkbox"
                                onChange={() => setStartFromZeroSecondY(!startFromZeroSecondY)}/>
                </Form.Group>
                <SelectInput
                    initialValue={aggregationOptions.slice(0, 1)} label="Agg. method" options={aggregationOptions}
                    handleChange={(selectedOptions) => {
                        if (selectedOptions.length === 0) setAggregationMethod("");
                        else setAggregationMethod(selectedOptions[0].value);
                    }}
                />
                <SelectInput
                    initialValue={[]} label="Add filters" options={numericalColumnOptions} isMultiple={true}
                    handleChange={(selectedOptions) => {
                        // Copy old selections/ranges, remove unselected options,
                        // and add selections/ranges for new options
                        const newExtraFilterInput = {};
                        selectedOptions.forEach((option) => {
                            const column = option.value;
                            if (extraFilterInput.hasOwnProperty(column))
                                newExtraFilterInput[column] = extraFilterInput[column];
                            else if (filterableColumnData[column].values.length > multipleSelectLimit)
                                newExtraFilterInput[column] = {
                                    from: filterableColumnData[column].min,
                                    fromValue: filterableColumnData[column].min,
                                    to: filterableColumnData[column].max,
                                    toValue: filterableColumnData[column].max
                                };
                            else newExtraFilterInput[column] = {selected: filterableColumnData[column].values};
                        })

                        setExtraFilterInput(newExtraFilterInput);
                    }}
                />
                {Object.entries(extraFilterInput).map((entry) => createFilterComponent(entry[0], entry[1]))}
            </Col>
            <Col md={8}>
                {
                    firstYAxis !== "" && aggregationMethod !== "" &&
                    <DoubleYAxisLineTimeChart
                        dataset={filteredDataset}
                        xAxisKey="monster_datum"
                        yAxisKey={firstYAxis}
                        y2AxisKey={secondYAxis}
                        xAxisLabel="MPR"
                        yAxisLabel={startCase(firstYAxis)}
                        y2AxisLabel={startCase(secondYAxis)}
                        xAxisFormatter={(unixTime) => moment(unixTime).format("DD-MM-YYYY")}
                        tooltipXAxisFormatter={(unixTime) => moment(unixTime).format("DD MMMM YYYY")}
                        yStartFromZero={startFromZeroFirstY}
                        y2StartFromZero={startFromZeroSecondY}
                    />
                }
            </Col>
        </Row>
    );
}

export default InputSide;