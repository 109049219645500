import React, {useContext, useState} from "react";
import {useHistory, useLocation} from "react-router-dom";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import {UserContext} from "contexts";
import {loginUser, logoutUser} from "api/auth";

function Login() {
    const queryParams = new URLSearchParams(useLocation().search);
    const history = useHistory();
    const {setUserDetails} = useContext(UserContext);

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [errorDisplay, setErrorDisplay] = useState("");

    const onLoginFormSubmit = (event) => {
        event.preventDefault();

        if (!isValidForm()) return;

        loginUser(username, password).then((data) => {
            setUserDetails(data);
            if (data.hasOwnProperty("user") && data["user"].hasOwnProperty("groups") &&
                data["user"]["groups"].some((group) => group.toUpperCase() === "THIRD_PARTY")) {
                logoutUser();
                setUserDetails(null);
                setErrorDisplay("You don't have the permission to use this website.")
            } else history.push(getRouteAfterLogin());
        }).catch((error) => setErrorDisplay(error.toString()));
    };

    const getRouteAfterLogin = () => {
        let route = queryParams.get("next");
        if (route === null) route = "/";

        return route;
    };

    const isValidForm = () => {
        setErrorDisplay("");

        if (username === "" || password === "") {
            setErrorDisplay("Username and password can't be empty.");

            return false;
        }

        return true;
    };

    return (
        <div>
            <Form onSubmit={onLoginFormSubmit} method="POST">
                <Form.Group>
                    <Form.Label>Username</Form.Label>
                    <Form.Control
                        onChange={(event) => setUsername(event.target.value)}
                        type="text" id="username" name="username"/>
                </Form.Group>
                <Form.Group>
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                        onChange={(event) => setPassword(event.target.value)}
                        type="password" id="password" name="password"/>
                </Form.Group>
                <Button type="submit">Login</Button>
                <p style={{color: "red"}}>{errorDisplay}</p>
            </Form>
        </div>
    );
}

export default Login;