import {Bar, BarChart, CartesianGrid, Label, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import React from "react";
import {round} from "lodash";

const colors = [
    "#538135", "#357881", "#FDD247", "#FCAC36", "#E14D03",
    "maroon", "aqua", "goldenrod", "darkslateblue", "darkgreen",
    "cadetblue", "chartreuse", "blueviolet", "deeppink", "brown",
    "burlywood", "coral", "darkgrey", "darkkhaki", "gold",
    "lavender", "olive", "blue", "saddlebrown", "black"
];

export function PercentageStackedBarChart({dataset, xAxisKey, xAxisLabel, yAxisLabel, stackId}) {
    return (
        <ResponsiveContainer>
            <BarChart data={dataset} margin={{left: 20, bottom: 20}}>
                <CartesianGrid strokeDasharray="10 10"/>
                <Tooltip
                    labelFormatter={(mpr) => "MPR " + mpr}
                    formatter={(percentage) => round(percentage, 2)}
                />
                <Legend verticalAlign="top"/>
                <XAxis dataKey={xAxisKey}>
                    <Label value={xAxisLabel} offset={0} position="bottom"/>
                </XAxis>
                <YAxis
                    label={{value: yAxisLabel, angle: -90, position: "left"}}
                    ticks={[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
                />
                {
                    Object.keys(dataset[0]).filter((k) => k !== xAxisKey).map((k, i) =>
                        <Bar
                            key={k}
                            dataKey={k}
                            stackId={stackId}
                            fill={colors[i % colors.length]}
                            unit="%"
                        />
                    )
                }
            </BarChart>
        </ResponsiveContainer>
    );
}