import React from "react";
import Table from "react-bootstrap/Table";
import {useBlockLayout, useTable, useSortBy} from "react-table";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function BasicTable({columns, data, useBlock}) {
    const {getTableProps, getTableBodyProps, headerGroups, rows, prepareRow} = useTable({
        columns,
        data
    }, useSortBy, useBlock ? useBlockLayout : "");

    return (
        <Table striped hover responsive size="sm" {...getTableProps()}>
            <thead>
            {headerGroups.map((headerGroup) =>
                <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) =>
                        <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                            {column.render("Header")}
                            {column.isSorted ? (column.isSortedDesc ?
                                <FontAwesomeIcon icon="sort-down"/> :
                                <FontAwesomeIcon icon="sort-up"/>)
                                : ""}
                        </th>
                    )}
                </tr>
            )}
            </thead>
            <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
                prepareRow(row);

                return (
                    <tr {...row.getRowProps()}>
                        {row.cells.map((cell) =>
                            <td {...cell.getCellProps()}>
                                {cell.render("Cell")}
                            </td>
                        )}
                    </tr>
                );
            })}
            </tbody>
        </Table>
    );
}

export default BasicTable;