import React, {useCallback, useMemo, useState} from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import Form from "react-bootstrap/Form";
import {toNumber, snakeCase} from "lodash";
import {useTable, useExpanded, useGroupBy} from "react-table";
import Table from "react-bootstrap/Table";
import {NumberInputGroup} from "components/InputGroup";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const assumption_averages = {
    US: {
        your_herd: {
            number_of_milking_cows: 5800,
            average_yield_day_cow: 100,
            current_milk_price: 0.15,
            labor_cost_hour: 10,
        },
        clinical_mastitis: {
            in_hospital_at_all_times_herd_year: 1,
            hospital_time_year: 7,
            treatment_time: 3,
            treatment_cost_day: 4.00,
            withdrawal_time: 4,
            ahv_treatment_cost: 45.00,
            ahv_withdrawal_time: 3,
            labor_hours: 1,
            ahv_labor_hours: 0.25,
        },
        subclinical_mastitis: {
            herd_above_200scc_perc: 10,
            treatment_cost: 0,
            ahv_treatment_cost: 39.00*1.20,
            labor_hours: 0,
            ahv_labor_hours: 0.25,
            milk_loss_200: 400 * 1.2,
            ahv_milk_loss: 0,
            new_cow_price: 2000 * 1.2,
            cow_mastitis_mortality: 2,
        },
        metritis: {
            occurrence_herd_year: 9,
            treatment_time: 3,
            treatment_cost_day: 17.00,
            withdrawal_time: 0,
            ahv_treatment_time: 2,
            ahv_treatment_cost: 13.00,
            ahv_withdrawal_time: 0,
        }
    },
    NL: {
        your_herd: {
            number_of_milking_cows: 100,
            average_yield_day_cow: 36,
            current_milk_price: 0.36,
            labor_cost_hour: 15,
        },
        clinical_mastitis: {
            in_hospital_at_all_times_herd_year: .14,
            hospital_time_year: 7,
            treatment_time: 3,
            treatment_cost_day: 13,
            withdrawal_time: 6,
            ahv_treatment_cost: 79,
            ahv_withdrawal_time: 0,
            labor_hours: 1,
            ahv_labor_hours: 0.25,
        },
        subclinical_mastitis: {
            herd_above_200scc_perc: 10,
            treatment_cost: 0,
            ahv_treatment_cost: 39.00,
            labor_hours: 0,
            ahv_labor_hours: 0.25,
            milk_loss_200: 400,
            ahv_milk_loss: 0,
            new_cow_price: 2000,
            cow_mastitis_mortality: 2,

        },
        metritis: {
            occurrence_herd_year: 9,
            treatment_time: 3,
            treatment_cost_day: 17.00,
            withdrawal_time: 0,
            ahv_treatment_time: 2,
            ahv_treatment_cost: 13.00,
            ahv_withdrawal_time: 0,
        }
    }
};

const calculations = {
    "clinical_mastitis": {
        "treatment_cost": {
            "bau": input =>
                input["clinical_mastitis"]["treatment_time"] * input["clinical_mastitis"]["treatment_cost_day"],
            "ahv": input => input["clinical_mastitis"]["ahv_treatment_cost"]
        },
        "milk_loss": {
            "bau": input =>
                (input["clinical_mastitis"]["treatment_time"] + input["clinical_mastitis"]["withdrawal_time"]) *
                input["your_herd"]["average_yield_day_cow"] * input["your_herd"]["current_milk_price"],
            "ahv": input =>
                input["clinical_mastitis"]["ahv_withdrawal_time"] *
                input["your_herd"]["average_yield_day_cow"] * input["your_herd"]["current_milk_price"]
        },
        "labor": {
            "bau": input => input["clinical_mastitis"]["labor_hours"] * input["your_herd"]["labor_cost_hour"],
            "ahv": input =>
                input["clinical_mastitis"]["ahv_labor_hours"] * input["your_herd"]["labor_cost_hour"]
        },
        "saving_herd": (input, diff) =>
            diff * 365 / input["clinical_mastitis"]["hospital_time_year"] * (
                input["clinical_mastitis"]["in_hospital_at_all_times_herd_year"] / 100 *
                input["your_herd"]["number_of_milking_cows"]
            )
    },
    "subclinical_mastitis": {
        "treatment_cost": {
            "bau": input => input["subclinical_mastitis"]["treatment_cost"],
            "ahv": input => input["subclinical_mastitis"]["ahv_treatment_cost"]
        },
        "milk_loss": {
            "bau": input => input["subclinical_mastitis"]["milk_loss_200"],
            "ahv": input => input["subclinical_mastitis"]["ahv_milk_loss"]
        },
        "labor": {
            "bau": input => input["subclinical_mastitis"]["labor_hours"] * input["your_herd"]["labor_cost_hour"],
            "ahv": input =>
                input["subclinical_mastitis"]["ahv_labor_hours"] * input["your_herd"]["labor_cost_hour"]
        },
        "saving_herd": (input, diff) =>
            diff * input["subclinical_mastitis"]["herd_above_200scc_perc"] / 100 * input["your_herd"]["number_of_milking_cows"]
    },
    "metritis": {
        "treatment_cost": {
            "bau": input => input["metritis"]["treatment_time"] * input["metritis"]["treatment_cost_day"],
            "ahv": input => input["metritis"]["ahv_treatment_time"] * input["metritis"]["ahv_treatment_cost"]
        },
        "milk_loss": {
            "bau": input =>
                (input["metritis"]["treatment_time"] + input["metritis"]["withdrawal_time"]) *
                input["your_herd"]["average_yield_day_cow"] * input["your_herd"]["current_milk_price"],
            "ahv": input =>
                input["metritis"]["ahv_withdrawal_time"] *
                input["your_herd"]["average_yield_day_cow"] * input["your_herd"]["current_milk_price"]
        },
        "saving_herd": (input, diff) =>
            diff * (
                input["metritis"]["occurrence_herd_year"] / 100 * input["your_herd"]["number_of_milking_cows"]
            )
    }
}

const countries = {
    US: {sign: "$", unit: "lb"},
    NL: {sign: "€", unit: "kg"}
};

export function ROITool() {
    const [country, setCountry] = useState("NL");
    const [input, setInput] = useState(assumption_averages[country]);

    const onInputChange = useCallback((kpi, assumption, value) => {
        const newInput = {...input};
        newInput[kpi] = {...newInput[kpi]};
        newInput[kpi][assumption] = value;
        setInput(newInput);
    }, [input]);

    const onCountryChange = useCallback((e) => {
        setCountry(e.target.value);
        setInput(assumption_averages[e.target.value]);
    }, []);

    return (
        <div className="shadow">
            <Row className="pt-3 pl-3 pr-3 justify-content-between">
                <Col md="auto">
                    <h4>ROI Tool</h4>
                </Col>
                <Col md="auto">
                    <Form>
                        <Form.Check inline label="NL" type="radio" value="NL" onChange={onCountryChange}
                                    checked={country === "NL"}/>
                        <Form.Check inline label="US" type="radio" value="US" onChange={onCountryChange}
                                    checked={country === "US"}/>
                    </Form>
                </Col>
            </Row>
            <Row className="pl-3 pr-3 justify-content-center">
                <Col md={9}>
                    <CostCalculationResults input={input} country={country}/>
                </Col>
            </Row>
            <Row className="mt-4 pl-3 pr-3 justify-content-center">
                <Col md={9}>
                    <InputSection input={input} onInputChange={onInputChange} country={country}/>
                </Col>
            </Row>
        </div>
    );
}

function InputSection({input, onInputChange, country}) {
    return (
        <>
            <h4 className="text-center">Your input data</h4>
            <Tabs>
                <TabList>
                    <Tab>Your herd</Tab>
                    <Tab>Clinical mastitis</Tab>
                    <Tab>Subclinical mastitis</Tab>
                    {/*<Tab>Ketosis</Tab>*/}
                    <Tab>Metritis</Tab>
                    {/*<Tab>Diarrhea (scour/crypto)</Tab>*/}
                    {/*<Tab>PNE/BRONC/RESP issues</Tab>*/}
                </TabList>

                <TabPanel>
                    <YourHerdInput input={input} onChange={onInputChange} country={country}/>
                </TabPanel>
                <TabPanel>
                    <ClinicalMastitisInput input={input} onChange={onInputChange} country={country}/>
                </TabPanel>
                <TabPanel>
                    <SubClinicalMastitisInput input={input} onChange={onInputChange} country={country}/>
                </TabPanel>
                {/*<TabPanel></TabPanel>*/}
                <TabPanel>
                    <MetritisInput input={input} onChange={onInputChange} country={country}/>
                </TabPanel>
                {/*<TabPanel></TabPanel>*/}
                {/*<TabPanel></TabPanel>*/}
            </Tabs>
        </>
    );
}

function YourHerdInput({input, onChange, country}) {
    const kpi = "your_herd";
    const unit = countries[country]["unit"];
    const sign = countries[country]["sign"];

    return (
        <Form>
            <FormItem kpi={kpi} assumption="number_of_milking_cows" label="# milking cows"
                      input={input} onChange={onChange} country={country}/>
            <FormItem kpi={kpi} assumption="average_yield_day_cow" label="Average yield/day/cow"
                      input={input} onChange={onChange} appendLabel={unit} country={country}/>
            <FormItem kpi={kpi} assumption="current_milk_price" label={"Current milk price/" + unit}
                      input={input} onChange={onChange} appendLabel={sign} country={country}/>
            <FormItem kpi={kpi} assumption="labor_cost_hour" label={"Labor costs/hour" + sign}
                      input={input} onChange={onChange} appendLabel={sign} country={country}/>
        </Form>
    );
}

function ClinicalMastitisInput({input, onChange, country}) {
    const kpi = "clinical_mastitis";
    const sign = countries[country]["sign"];

    return (
        <Form>
            <FormItem kpi={kpi} assumption="in_hospital_at_all_times_herd_year"
                      label="In hospital at all times/herd/year" input={input} onChange={onChange} appendLabel="%"
                      country={country}/>
            <FormItem kpi={kpi} assumption="hospital_time_year" label="Hospital time/year"
                      input={input} onChange={onChange} appendLabel="days" country={country}/>
            <FormItem kpi={kpi} assumption="treatment_time" label="Treatment time"
                      input={input} onChange={onChange} appendLabel="days" country={country}/>
            <FormItem kpi={kpi} assumption="treatment_cost_day" label="Treatment cost/day"
                      input={input} onChange={onChange} appendLabel={sign} country={country}/>
            <FormItem kpi={kpi} assumption="withdrawal_time" label="Withdrawal time"
                      input={input} onChange={onChange} appendLabel="days" country={country}/>
            <FormItem kpi={kpi} assumption="ahv_treatment_cost" label="AHV treatment cost"
                      input={input} onChange={onChange} appendLabel={sign} country={country}/>
            <FormItem kpi={kpi} assumption="ahv_withdrawal_time" label="AHV withdrawal time"
                      input={input} onChange={onChange} appendLabel="days" country={country}/>
            <FormItem kpi={kpi} assumption="labor_hours" label="Labor hours"
                      input={input} onChange={onChange} country={country}/>
            <FormItem kpi={kpi} assumption="ahv_labor_hours" label="AHV labor hours"
                      input={input} onChange={onChange} country={country}/>
        </Form>
    );
}

function SubClinicalMastitisInput({input, onChange, country}) {
    const kpi = "subclinical_mastitis";
    const sign = countries[country]["sign"];

    return (
        <Form>
            <FormItem kpi={kpi} assumption="herd_above_200scc_perc"
                      label="Herd composition of SCC > 200 throughout lactation" input={input} onChange={onChange} appendLabel="%"
                      country={country}/>
            <FormItem kpi={kpi} assumption="ahv_treatment_cost" label="AHV treatment cost"
                      input={input} onChange={onChange} appendLabel={sign} country={country}/>
            <FormItem kpi={kpi} assumption="ahv_labor_hours" label="AHV labor hours"
                      input={input} onChange={onChange} country={country}/>
        </Form>
    );
}

function MetritisInput({input, onChange, country}) {
    const kpi = "metritis";
    const sign = countries[country]["sign"];

    return (
        <Form>
            <FormItem kpi={kpi} assumption="occurrence_herd_year" label="% metritis occurence/herd/year"
                      input={input} onChange={onChange} appendLabel="%" country={country}/>
            <FormItem kpi={kpi} assumption="treatment_time" label="Treatment time"
                      input={input} onChange={onChange} appendLabel="days" country={country}/>
            <FormItem kpi={kpi} assumption="treatment_cost_day" label="Treatment cost/day"
                      input={input} onChange={onChange} appendLabel={sign} country={country}/>
            <FormItem kpi={kpi} assumption="withdrawal_time" label="Withdrawal time"
                      input={input} onChange={onChange} appendLabel="days" country={country}/>
            <FormItem kpi={kpi} assumption="ahv_treatment_time" label="AHV treatment time"
                      input={input} onChange={onChange} appendLabel="days" country={country}/>
            <FormItem kpi={kpi} assumption="ahv_treatment_cost" label="AHV treatment cost"
                      input={input} onChange={onChange} appendLabel={sign} country={country}/>
            <FormItem kpi={kpi} assumption="ahv_withdrawal_time" label="AHV withdrawal time"
                      input={input} onChange={onChange} appendLabel="days" country={country}/>
        </Form>
    );
}

function FormItem({kpi, assumption, label, input, onChange, appendLabel, country}) {
    return (
        <Form.Group as={Row} controlId={"form-" + kpi + "-" + assumption} className="justify-content-center">
            <Form.Label column sm={4}>{label}</Form.Label>
            <Col sm={4}>
                <NumberInputGroup
                    appendLabel={appendLabel} defaultValue={input[kpi][assumption]}
                    onChange={(event) => onChange(kpi, assumption,
                        event.target.value === "" ?
                            assumption_averages[country][assumption] : toNumber(event.target.value))}
                />
            </Col>
        </Form.Group>
    );
}

function CostCalculationResults({input, country}) {
    const data = useMemo(() => [
        {main: "Clinical mastitis", sub: "Treatment cost", calc: calculations["clinical_mastitis"]["treatment_cost"]},
        {main: "Clinical mastitis", sub: "Milk loss", calc: calculations["clinical_mastitis"]["milk_loss"]},
        {main: "Clinical mastitis", sub: "Labor", calc: calculations["clinical_mastitis"]["labor"]},
        {main: "Subclinical mastitis", sub: "Treatment cost", calc: calculations["subclinical_mastitis"]["treatment_cost"]},
        {main: "Subclinical mastitis", sub: "Milk loss", calc: calculations["subclinical_mastitis"]["milk_loss"]},
        {main: "Subclinical mastitis", sub: "labor", calc: calculations["subclinical_mastitis"]["labor"]},

        {main: "Metritis", sub: "Treatment cost", calc: calculations["metritis"]["treatment_cost"]},
        {main: "Metritis", sub: "Milk loss", calc: calculations["metritis"]["milk_loss"]},
    ], [input, country]);

    return (
        <>
            <h4 className="text-center">Cost calculation results</h4>
            <ResultTable country={country} data={data} input={input}/>
        </>
    );
}

function ResultTable({country, data, input}) {
    const columns = useMemo(() => [
        {
            Header: "Category", columns: [
                {Header: "Main", accessor: "main", disableGroupBy: false, Cell: ({value}) => String(value)},
                {Header: "Sub", accessor: "sub", Aggregated: () => "Total", Cell: ({value}) => String(value)}
            ]
        },
        {
            Header: "Cost/cow", columns: [
                {Header: "BAU", accessor: row => row["calc"]["bau"](input), aggregate: "sum"},
                {Header: "AHV", accessor: row => row["calc"]["ahv"](input), aggregate: "sum"}
            ]
        },
        {
            Header: "Saving", columns: [
                {
                    Header: "Cow",
                    accessor: row => row["calc"]["bau"](input) - row["calc"]["ahv"](input),
                    aggregate: "sum"
                },
                {
                    Header: "Year/herd",
                    accessor: row =>
                        calculations[snakeCase(row["main"])]["saving_herd"]
                        (input, row["calc"]["bau"](input) - row["calc"]["ahv"](input)),
                    aggregate: "sum",
                    Cell: ({value}) => countries[country]["sign"] + " " +
                        value.toLocaleString(undefined, {maximumFractionDigits: 0}),
                }
            ]
        }
    ], [input, country]);
    const {getTableProps, getTableBodyProps, headerGroups, rows, prepareRow} = useTable({
        initialState: {groupBy: ["main"]},
        defaultColumn: {
            Cell: ({value}) => countries[country]["sign"] + " " +
                value.toLocaleString(undefined, {maximumFractionDigits: 2}),
            disableGroupBy: true
        },
        autoResetExpanded: false,
        columns,
        data
    }, useGroupBy, useExpanded);

    return (
        <Table hover responsive size="sm" {...getTableProps()}>
            <thead>
            {headerGroups.map((headerGroup) =>
                <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map(column =>
                        <th {...column.getHeaderProps()}>
                            {column.render("Header")}
                        </th>)}
                </tr>
            )}
            </thead>
            <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
                prepareRow(row);

                return (
                    <tr {...row.getRowProps()} style={row.canExpand ? {backgroundColor: "rgba(0,0,0,.05)"} : {}}>
                        {row.cells.map((cell) =>
                            <td {...cell.getCellProps()}>
                                {cell.isGrouped ?
                                    <>
                                        <span {...row.getToggleRowExpandedProps()}>
                                            {row.isExpanded ?
                                                <FontAwesomeIcon icon="minus"/> :
                                                <FontAwesomeIcon icon="plus"/>}
                                        </span>
                                        {" "}
                                        {cell.render("Cell")}
                                    </> :
                                    cell.isAggregated ? cell.render("Aggregated") :
                                        cell.isPlaceholder ? null : cell.render("Cell")
                                }
                            </td>
                        )}
                    </tr>
                );
            })}
            </tbody>
        </Table>
    );
}