import React, {useContext, useState} from "react";
import {ErrorMessage, Field, Form as FormikForm, Formik} from "formik";
import * as Yup from "yup";
import {changePassword} from "api/rest";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import {UserContext} from "contexts";

const validationSchema = Yup.object({
    password: Yup.string().required("Required."),
    password2: Yup.string().required("Required."),
    old_password: Yup.string().required("Required.")
})

function ProfilePage() {
    const {userDetails} = useContext(UserContext);

    const [errorDisplay, setErrorDisplay] = useState("");
    const [successDisplay, setSuccessDisplay] = useState("");

    const submitPasswordChange = (values, actions) => {
        setErrorDisplay("");
        setSuccessDisplay("");

        const body = {
            password: values.password,
            password2: values.password2,
            old_password: values.old_password
        };

        changePassword(userDetails["user"]["id"], body)
            .then((data) => setSuccessDisplay("Successfully changed password!"))
            .catch((error) => {
                console.log(error.toString());
                if (error.response.headers["content-type"] === "text/html") setErrorDisplay(error.toString());
                else if (error.response.headers["content-type"].endsWith("/json")) {
                    Object.keys(error.response.data).forEach((field) => {
                        if (field === "non_field_errors") setErrorDisplay(error.response.data["non_field_errors"]);
                        else actions.setFieldError(field, error.response.data[field]);
                    });
                }
            });
    };

    const initialValues = {password: "", password2: "", old_password: ""};

    return (
        <div>
            <h1>Profile page</h1>
            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={submitPasswordChange}>
                {formik => (
                    <FormikForm>
                        <Form.Row>
                            <Form.Group as={Col} sm controlId="password">
                                <Form.Label>Password: </Form.Label>
                                <Field as={Form.Control} name="password" type="password"/>
                                <ErrorMessage name="password" component="span" className="text-danger"/>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} sm controlId="password2">
                                <Form.Label>Password (again): </Form.Label>
                                <Field as={Form.Control} name="password2" type="password"/>
                                <ErrorMessage name="password2" component="span" className="text-danger"/>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} sm controlId="old_password">
                                <Form.Label>Old password: </Form.Label>
                                <Field as={Form.Control} name="old_password" type="password"/>
                                <ErrorMessage name="old_password" component="span" className="text-danger"/>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Col>
                                <Button className="mt-3" type="submit">Change password</Button>
                            </Col>
                        </Form.Row>
                    </FormikForm>
                )}
            </Formik>
            <p className="text-danger">{errorDisplay}</p>
            <p className="text-success">{successDisplay}</p>
        </div>
    );
}

export default ProfilePage;