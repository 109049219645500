import React from "react";
import {CartesianGrid, Label, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";

const colors = [
    "maroon", "aqua", "goldenrod", "darkslateblue", "darkgreen",
    "cadetblue", "chartreuse", "blueviolet", "deeppink", "brown",
    "burlywood", "coral", "darkgrey", "darkkhaki", "gold",
    "lavender", "olive", "blue", "saddlebrown", "black"
];

export function SeriesLineTimeChart(
    {series, xAxisKey, yAxisKey, xAxisLabel, yAxisLabel, xAxisFormatter, tooltipXAxisFormatter}) {
    return (
        <ResponsiveContainer>
            <LineChart margin={{left: 20, bottom: 20}}>
                <CartesianGrid strokeDasharray="10 10"/>
                {!!tooltipXAxisFormatter ? <Tooltip labelFormatter={tooltipXAxisFormatter}/> : <Tooltip/>}
                <Legend verticalAlign="top"/>
                <XAxis
                    dataKey={xAxisKey}
                    type="number"
                    domain={["auto", "auto"]}
                    scale="time"
                    tickFormatter={xAxisFormatter}
                    allowDuplicatedCategory={false}
                    interval={"preserveStart"}
                >
                    <Label value={xAxisLabel} offset={0} position="bottom"/>
                </XAxis>
                <YAxis
                    label={{value: yAxisLabel, angle: -90, position: "left"}}
                    domain={["auto", "auto"]}
                />
                {
                    Object.entries(series).map(([name, data], i) =>
                        <Line
                            type="monotone"
                            dataKey={yAxisKey}
                            data={data}
                            name={name}
                            key={name}
                            stroke={colors[i % colors.length]}
                            strokeWidth={2}
                            dot={{strokeWidth: 1}}
                            activeDot={{r: 5}}
                        />)
                }
            </LineChart>
        </ResponsiveContainer>
    );
}

export function DoubleYAxisLineTimeChart(
    {
        dataset, xAxisKey, yAxisKey, y2AxisKey, xAxisLabel, yAxisLabel, y2AxisLabel,
        xAxisFormatter, tooltipXAxisFormatter, yStartFromZero, y2StartFromZero
    }) {
    return (
        <ResponsiveContainer>
            <LineChart data={dataset} margin={{left: 20, right: 20, bottom: 20}}>
                <CartesianGrid strokeDasharray="10 10"/>
                {!!tooltipXAxisFormatter ? <Tooltip labelFormatter={tooltipXAxisFormatter}/> : <Tooltip/>}
                <Legend verticalAlign="top"/>
                <XAxis
                    dataKey={xAxisKey}
                    type="number"
                    domain={["auto", "auto"]}
                    scale="time"
                    tickFormatter={xAxisFormatter}
                    allowDuplicatedCategory={false}
                    interval={"preserveStart"}
                >
                    <Label value={xAxisLabel} offset={0} position="bottom"/>
                </XAxis>
                {
                    yAxisLabel !== "" &&
                    <YAxis
                        yAxisId="left"
                        label={{value: yAxisLabel, angle: -90, position: "left"}}
                        domain={[!!yStartFromZero ? 0 : "auto", "auto"]}
                    />
                }
                {
                    yAxisKey !== "" &&
                    <Line
                        type="monotone"
                        yAxisId="left"
                        dataKey={yAxisKey}
                        stroke={colors[4]}
                        strokeWidth={2}
                        dot={{r: 1.5, strokeWidth: 1}}
                        activeDot={{r: 5}}
                    />
                }
                {
                    y2AxisLabel !== "" &&
                    <YAxis
                        yAxisId="right"
                        label={{value: y2AxisLabel, angle: 90, position: "right"}}
                        domain={[y2StartFromZero ? 0 : "auto", "auto"]}
                        orientation="right"
                    />
                }
                {
                    y2AxisKey !== "" &&
                    <Line
                        type="monotone"
                        yAxisId="right"
                        dataKey={y2AxisKey}
                        stroke={colors[17]}
                        strokeWidth={2}
                        dot={{r: 1.5, strokeWidth: 1}}
                        activeDot={{r: 5}}
                    />
                }
            </LineChart>
        </ResponsiveContainer>
    );
}