import React, {useCallback, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {BasicTable, FarmNavigationButtonGroup} from "components";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import {useFarmTitle} from "states";
import {postTreatmentAnalysis} from "api/rest";

export function TreatmentAnalysis() {
    const {id} = useParams();
    const {farmTitle} = useFarmTitle();

    const [message, setMessage] = useState("");
    const [dataset, setDataset] = useState([]);

    const submitRequestDataset = useCallback((id) => {
        setMessage("");
        postTreatmentAnalysis(id, {medicines: ["Quick", "Extra"]})
            .then((data) => setDataset(data))
            .catch((error) => {
                if (error.response.data !== "" && error.response.data.hasOwnProperty("message"))
                    setMessage(error.response.data.message);
                else console.log(error.toString());
            });
    }, []);

    useEffect(() => submitRequestDataset(id), [id, submitRequestDataset]);

    return (
        <div className="shadow">
            <Row className="justify-content-between pt-3 pl-3 pr-3">
                <Col md="auto">
                    <h4>{farmTitle}</h4>
                </Col>
                <Col md="auto">
                    <FarmNavigationButtonGroup id={id}/>
                </Col>
            </Row>
            <Row className="pl-3 pr-3 pb-3">
                <Col md="auto">
                    <h4>Treatment Analysis</h4>
                </Col>
            </Row>
            <Row className="pl-3 pr-3 pb-3">
                <Col>
                    {dataset.length !== 0 && <AnalysisTable dataset={dataset}/>}
                </Col>
            </Row>
            <Row className="justify-content-center">
                <Col md="auto">
                    <b>{message}</b>
                </Col>
            </Row>
        </div>
    );
}

function AnalysisTable({dataset}) {
    const columns = React.useMemo(() => {
        const columns = [
            {Header: "Parity", accessor: "parity"},
            {Header: "Treated", accessor: "count"},
            {Header: "At farm", accessor: "at_farm"},
        ];

        const departureReasonAccessors = Object.keys(dataset[0])
            .filter((c) => c.startsWith("departure:"))
            .sort();
        const departureColumns = departureReasonAccessors.map((accessor) => (
            {
                Header: accessor.split(":")[1],
                accessor: row => row[accessor] !== 0 ? row[accessor] : ""
            }));

        const productionColumns = [
            {Header: "ISK", accessor: "isk"},
            {Header: "Lactation value", accessor: "lactation_value"},
            {Header: "Milk yield / expected", accessor: "milk_yield_divided_by_exp"},
        ];

        columns.push({Header: "Departure reason", columns: departureColumns});
        columns.push({Header: "Production", columns: productionColumns});

        return columns;
    }, [dataset]);

    return (
        <BasicTable columns={columns} data={dataset}/>
    );
}