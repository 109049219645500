import axios from "axios";

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

let tokenRequest = axios.create({
    baseURL: BASE_URL,
    timeout: 5000,
    headers: {
        "Content-Type": "application/json",
        "accept": "application/json"
    }
});

const loginUser = (username, password) => {
    const loginBody = {username: username, password: password};

    return tokenRequest.post("/token/create/", loginBody)
        .then((response) => {
            window.localStorage.setItem("ahv_user", JSON.stringify(response.data));
            authRequest.defaults.headers["Authorization"] = "Bearer " + response.data.access;

            return Promise.resolve(response.data);
        }).catch((error) => {
            return Promise.reject(error);
        });
};

const refreshToken = () => {
    const tokens = JSON.parse(window.localStorage.getItem("ahv_user"));
    const refreshBody = {"refresh": tokens ? tokens.refresh : null};

    return tokenRequest.post("/token/refresh/", refreshBody)
        .then((response) => {
            tokens.access = response.data.access;
            window.localStorage.setItem("ahv_user", JSON.stringify(tokens));
            authRequest.defaults.headers["Authorization"] = "Bearer " + response.data.access;

            return Promise.resolve(response.data);
        }).catch((error) => {
            return Promise.reject(error);
        });
};

const isCorrectRefreshError = (status) => status === 401;

/*
 * authRequest
 *
 * This refreshes the request and retries the token if it is invalid.
 * This is what you use to create any requests that need the tokens.
 */
const tokens = JSON.parse(window.localStorage.getItem("ahv_user"));
const authRequest = axios.create({
    baseURL: BASE_URL,
    timeout: 60000,
    headers: {
        "Authorization": "Bearer " + (tokens ? tokens.access : null),
        "Content-Type": "application/json"
    }
});
authRequest.interceptors.response.use(
    (response) => response, // this is for all successful requests.
    (error) => { //handle the request
        return errorInterceptor(error);
    }
);

const errorInterceptor = (error) => {
    const originalRequest = error.config;
    const status = error.response.status;

    if (isCorrectRefreshError(status)) {
        return refreshToken().then((data) => {
            const tokens = JSON.parse(window.localStorage.getItem("ahv_user"));
            const headerAuthorization = "Bearer " + (tokens ? tokens.access : null);
            authRequest.defaults.headers["Authorization"] = headerAuthorization;
            originalRequest.headers["Authorization"] = headerAuthorization;

            return authRequest(originalRequest);
        }).catch((error) => {
            logoutUser();

            return Promise.reject(error);
        })
    }

    return Promise.reject(error);
};

const logoutUser = () => {
    window.localStorage.removeItem("ahv_user");
    authRequest.defaults.headers["Authorization"] = "";
};

export {
    tokenRequest,
    loginUser,
    logoutUser,
    refreshToken,
    authRequest,
    errorInterceptor,
    BASE_URL,
}