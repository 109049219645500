import React from "react";
import {ErrorMessage, useField} from "formik";
import Datetime from "react-datetime";

function FormikDate({label, initialValue, ...props}) {
    // eslint-disable-next-line
    const [field, meta, helpers] = useField(props);

    const {setValue} = helpers;

    return (
        <div>
            <label htmlFor={props.id || props.name}>{label}</label>
            <Datetime
                onChange={(date) => {
                    if (typeof date === "string") setValue(null);
                    else setValue(date.format("YYYY-MM-DD"));
                }}
                timeFormat={false}
                initialValue={initialValue}
                closeOnSelect={true}
                input={true}
            />
            <ErrorMessage name={field.name} component="span" className="text-danger"/>
        </div>
    );
}

export default FormikDate;