import React, {useContext} from "react";
import {useHistory} from "react-router-dom";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import {UserContext} from "contexts";
import {isMobileOnly} from "react-device-detect";

function FarmNavigationButtonGroup({id}) {
    const history = useHistory();
    const {inUserGroups} = useContext(UserContext);

    const onClickResearch = (location_id) => history.push("/farms/" + location_id + "/individual_research");
    const onClickTreatments = (location_id) => history.push("/farms/" + location_id + "/treatments");
    const onClickTreatmentAnalysis = (location_id) => history.push("/farms/" + location_id + "/treatment_analysis");
    const onClickDashboard = (location_id) => history.push("/farms/" + location_id + "/dashboard");
    const onClickAggregatedResearch = (location_id) => history.push("/farms/" + location_id + "/aggregated_research");
    const onClickAnimalsResearch = (location_id) => history.push("/farms/" + location_id + "/animals_research");
    const onClickNewCA = (location_id) => history.push("/farms/" + location_id + "/ca");
    const onClickTreatedSCCEvolution = (location_id) =>
        history.push("/farms/" + location_id + "/treated_scc_evolution");

    return (
        !isMobileOnly &&
        <ButtonGroup>
            <DropdownButton as={ButtonGroup} variant="outline-primary" title="Analyses" className="mr-2">
                <Dropdown.Item onClick={() => onClickNewCA(id)}>Consultant</Dropdown.Item>
                {
                    inUserGroups(["ADMIN", "RESEARCHER"]) &&
                    <Dropdown.Item onClick={() => onClickTreatmentAnalysis(id)}>Treatment</Dropdown.Item>
                }
                {
                    inUserGroups(["ADMIN", "RESEARCHER"]) &&
                    <Dropdown.Item onClick={() => onClickTreatedSCCEvolution(id)}>Treated SCC Evolution</Dropdown.Item>
                }
            </DropdownButton>
            <DropdownButton as={ButtonGroup} variant="outline-primary" title="Research" className="mr-2">
                <Dropdown.Item onClick={() => onClickAggregatedResearch(id)}>Aggregated</Dropdown.Item>
                <Dropdown.Item onClick={() => onClickResearch(id)}>Individual</Dropdown.Item>
                <Dropdown.Item onClick={() => onClickAnimalsResearch(id)}>Animals</Dropdown.Item>
            </DropdownButton>
            <Button variant="outline-primary" className="mr-2" onClick={() => onClickTreatments(id)}>
                Treatments
            </Button>
            <Button variant="outline-primary" onClick={() => onClickDashboard(id)}>Dashboard</Button>
        </ButtonGroup>
    );
}

export default FarmNavigationButtonGroup;