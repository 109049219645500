import React from "react";
import {Login} from "components";

function LoginPage() {
    return (
        <div>
            <h1>Login page</h1>
            <Login/>
        </div>
    );
}

export default LoginPage;