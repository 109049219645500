import {authRequest} from "./auth";

// For now limit=10000 until we make our own remote data sources with custom filtering, searching, ordering inside the
// data tables.

const getFarms = () => {
    return authRequest.get("/farms/?limit=10000")
        .then(response => Promise.resolve(response.data))
        .catch((error) => Promise.reject(error));
};

const getFarm = (id) => {
    return authRequest.get("/farms/" + id + "/")
        .then(response => Promise.resolve(response.data))
        .catch((error) => Promise.reject(error));
};

const getAnimalsAtFarm = (id) => {
    return authRequest.get("/farms/" + id + "/animals_at_farm/?limit=10000")
        .then(response => Promise.resolve(response.data))
        .catch((error) => Promise.reject(error));
};

const getAnimalsLeftFarm = (id) => {
    return authRequest.get("/farms/" + id + "/animals_left_farm/?limit=10000")
        .then(response => Promise.resolve(response.data))
        .catch((error) => Promise.reject(error));
};

const getLastMprDates = () => {
    return authRequest.get("/farms/last_mpr_dates")
        .then(response => Promise.resolve(response.data))
        .catch((error) => Promise.reject(error));
};

const getFarmTreatments = (id, params) => {
    const payload = ["limit=10000", ...params].join("&");
    return authRequest.get("/farms/" + id + "/treatments/?" + payload)
        .then(response => Promise.resolve(response.data))
        .catch((error) => Promise.reject(error));
};

const getFarmTreatmentAnimalOptions = (id) => {
    return authRequest.get("/farms/" + id + "/treatment_animal_options/")
        .then(response => Promise.resolve(response.data))
        .catch((error) => Promise.reject(error));
};

const getTreatmentOptions = () => {
    return authRequest.get("/treatments/treatment_options/")
        .then(response => Promise.resolve(response.data))
        .catch((error) => Promise.reject(error));
};

const postTreatment = (body) => {
    return authRequest.post("/treatments/", body)
        .then((response) => Promise.resolve(response.data))
        .catch((error) => Promise.reject(error));
};

const deleteTreatment = (id) => {
    return authRequest.delete("/treatments/" + id)
        .then((response) => Promise.resolve(response.data))
        .catch((error) => Promise.reject(error));
};

const getFarmResearchData = (id, monster_date_from, monster_date_to) => {
    return authRequest.get("/research/" + id + "/research_dataset/" +
        "?monster_date_from=" + monster_date_from + "&monster_date_to=" + monster_date_to)
        .then(response => Promise.resolve(response.data))
        .catch((error) => Promise.reject(error));
};

const sendFarmCAMail = (id, params) => {
    const payload = params.join("&");
    return authRequest.get("/research/" + id + "/send_ca_mail/?" + payload)
        .then(response => Promise.resolve(response.data))
        .catch((error) => Promise.reject(error));
};

const changePassword = (id, body) => {
    return authRequest.put("/change_password/" + id + "/", body)
        .then(response => Promise.resolve(response.data))
        .catch((error) => Promise.reject(error));
};

const getCurrentUser = () => {
    return authRequest.get("/users/current_user/")
        .then(response => Promise.resolve(response.data))
        .catch((error) => Promise.reject(error));
};

const postTreatmentAnalysis = (id, body) => {
    return authRequest.post("/research/" + id + "/treatment_analysis_dataset/", body)
        .then(response => Promise.resolve(response.data))
        .catch((error) => Promise.reject(error));
};

const getAggregatedResearch = (id) => {
    return authRequest.get("/research/" + id + "/aggregated_research_dataset/")
        .then(response => Promise.resolve(response.data))
        .catch((error) => Promise.reject(error));
};

const getAnimalsResearch = (id) => {
    return authRequest.get("/research/" + id + "/animals_research_dataset/")
        .then(response => Promise.resolve(response.data))
        .catch((error) => Promise.reject(error));
};

const getMilkYieldFarm = (id) => {
    return authRequest.get("/research/" + id + "/milk_yield_farm_dataset/")
        .then(response => Promise.resolve(response.data))
        .catch((error) => Promise.reject(error));
};

const getSCCFarm = (id) => {
    return authRequest.get("/research/" + id + "/scc_farm_dataset/")
        .then(response => Promise.resolve(response.data))
        .catch((error) => Promise.reject(error));
};

const getFarmOverview = (id) => {
    return authRequest.get("/research/" + id + "/farm_overview/")
        .then(response => Promise.resolve(response.data))
        .catch((error) => Promise.reject(error));
};

const getConsultantAnalysis = (id, params) => {
    const payload = params.join("&");
    return authRequest.get("/research/" + id + "/consultant_analysis_dataset/?" + payload)
        .then(response => Promise.resolve(response.data))
        .catch((error) => Promise.reject(error));
};

const getTreatedSCCEvolution = (id) => {
    return authRequest.get("/research/" + id + "/treated_scc_evolution_dataset/")
        .then(response => Promise.resolve(response.data))
        .catch((error) => Promise.reject(error));
};

export {
    getFarms,
    getFarm,
    getAnimalsAtFarm,
    getAnimalsLeftFarm,
    getLastMprDates,
    getFarmTreatments,
    getFarmTreatmentAnimalOptions,
    getTreatmentOptions,
    postTreatment,
    deleteTreatment,
    getFarmResearchData,
    sendFarmCAMail,
    changePassword,
    getCurrentUser,
    postTreatmentAnalysis,
    getAggregatedResearch,
    getAnimalsResearch,
    getMilkYieldFarm,
    getSCCFarm,
    getFarmOverview,
    getConsultantAnalysis,
    getTreatedSCCEvolution
}