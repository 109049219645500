import React from "react";

function TestPage() {
    return (
        <div>
            <iframe
                title={"test"}
                src={"https://qlikahv.2exact.com/single/?appid=5887d3c4-1cf6-" +
                "4298-bdbe-22a5d883b3f3&obj=66dde6b6-aec1-427f-9c65-c944de0b9afc&opt=ctxmenu,currsel"}
                style={{border: null, width: "100%", height: "80vh"}}/>
        </div>
    );
}

export default TestPage;