import React, {Fragment} from "react";
import {ErrorMessage, useField} from "formik";
import {Typeahead, Menu, MenuItem} from 'react-bootstrap-typeahead';
import {groupBy} from "lodash";

function FormikSelect({label, ...props}) {
    // eslint-disable-next-line
    const [field, meta, helpers] = useField(props);

    const {options, isDisabled, isMulti, useGroup} = props;
    const {setValue} = helpers;

    const renderMenu = !useGroup ?
        (results, menuProps) =>
            <Menu {...menuProps}>
                {results
                    .sort((a, b) => (a.label > b.label) - (a.label < b.label))
                    .map((result, index) => <MenuItem option={result} position={index} key={index}>
                        {result.label}
                    </MenuItem>)}
            </Menu> :
        (results, menuProps) => {
            let index = 0;
            const groups = groupBy(results, "group");
            const items = Object.keys(groups).sort().map((group) => (
                <Fragment key={group}>
                    {index !== 0 && <Menu.Divider/>}
                    <Menu.Header>{group}</Menu.Header>
                    {groups[group]
                        .sort((a, b) => (a.label > b.label) - (a.label < b.label))
                        .map((i) => {
                            const item =
                                <MenuItem key={index} option={i} position={index}>
                                    {i.label}
                                </MenuItem>
                            index++;
                            return item;
                        })}
                </Fragment>
            ));

            return <Menu {...menuProps}>{items}</Menu>;
        }

    return (
        <div>
            <label htmlFor={props.id || props.name}>{label}</label>
            <Typeahead
                id={"typeahead-" + props.name}
                multiple={isMulti}
                options={options}
                onChange={(selectedOptions) => {
                    if (isMulti) setValue(selectedOptions.map((selectedOption) => selectedOption.value));
                    else {
                        if (selectedOptions.length === 0) setValue("");
                        else setValue(selectedOptions[0].value);
                    }
                }}
                disabled={isDisabled}
                clearButton
                renderMenu={renderMenu}
                maxResults={99999999}
            />
            <ErrorMessage name={field.name} component="span" className="text-danger"/>
        </div>
    );
}

export default FormikSelect;