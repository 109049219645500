import React, {useCallback, useState} from "react";
import {deleteTreatment} from "api/rest";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {BasicTable} from "components";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import moment from "moment";

function FarmTreatmentTable({treatments, refreshTreatments}) {
    const [showModal, setShowModal] = useState(false);
    const [treatmentId, setTreatmentId] = useState(null);

    const toggleShow = useCallback(() => setShowModal(!showModal), [showModal]);
    const handleTrashClick = useCallback((id) => {
        setTreatmentId(id);
        toggleShow();
    }, [toggleShow]);
    const handleDelete = useCallback(() => {
        deleteTreatment(treatmentId)
            .then((data) => refreshTreatments())
            .catch((error) => console.log(error.toString()));

        toggleShow();
    }, [toggleShow, refreshTreatments, treatmentId]);

    const columns = React.useMemo(() => [
        {Header: "Date", accessor: "date_time", Cell: ({value}) => moment(String(value)).format("DD-MM-YYYY")},
        {Header: "Animal id.", accessor: "animal_detail.identifier.identifier_type_id"},
        {Header: "Tag", accessor: "animal_detail.management_tag"},
        {Header: "Treatment", accessor: "treatment.name"},
        {Header: "Dose", accessor: "dose"},
        {Header: "Reason", accessor: "reason.name"},
        {Header: "Comment", accessor: "comment", disableSortBy: true},
        {Header: "Unreliable fields", id: "unreliable", accessor: row => row.unreliable.join(", ")},
        {Header: "Unreliable reason", accessor: "unreliable_reason", disableSortBy: true},
        {Header: "Note", accessor: "note", disableSortBy: true},
        {
            Header: "", id: "delete", accessor: row => row.id, disableSortBy: true,
            Cell: ({value}) => <FontAwesomeIcon color="tomato" icon="trash-alt"
                                                onClick={() => handleTrashClick(value)}/>
        }
    ], [handleTrashClick]);

    return (
        <div>
            <BasicTable columns={columns} data={treatments}/>
            <Modal show={showModal} onHide={toggleShow} animation={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Treatment deletion confirmation</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to delete this treatment?</Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={toggleShow}>Close</Button>
                    <Button variant="danger" onClick={handleDelete}>Delete</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default FarmTreatmentTable;