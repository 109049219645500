import React from "react";
import {Form, InputGroup} from "react-bootstrap";
import {Typeahead} from "react-bootstrap-typeahead";

function SelectInput({initialValue, label, options, handleChange, isMultiple}) {
    return (
        <Form.Group controlId={"control-" + label}>
            <InputGroup>
                <InputGroup.Prepend>
                    <InputGroup.Text>{label}</InputGroup.Text>
                </InputGroup.Prepend>
                <Typeahead
                id={"typeahead-" + label}
                options={options}
                clearButton
                defaultSelected={initialValue}
                onChange={handleChange}
                multiple={!!isMultiple}
                maxResults={99999999}
            />
            </InputGroup>
        </Form.Group>
    );
}

export default SelectInput;