import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import AutoSizer from "react-virtualized-auto-sizer";
import {VariableSizeList} from "react-window";
import styled from "styled-components";

const Styles = styled.div`
.tr { display:table-row; }
.thead { display: table-header-group; }
.td, .th { display: table-cell; }
.td, .th, .tr { vertical-align: inherit }
.th { font-weight: bolder; }

.table .th, .table .td {
  padding: 0.75rem;
  vertical-align: top;
}

.table .td { border-top: 1px solid #dee2e6; }

.table .thead .th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}

.table .tbody + .tbody { border-top: 2px solid #dee2e6; }

.table-sm .th, .table-sm .td { padding: 0.3rem; }

.table-hover .tbody .tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075);
}
`


export function Header({headerGroups}) {
    return (
        <div>
            {headerGroups.map((headerGroup, i) =>
                <div key={i} {...headerGroup.getHeaderGroupProps()} className="tr">
                    {headerGroup.headers.map((column, j) =>
                        <div key={j} {...column.getHeaderProps()} className="th">
                            <div className="mb-2">
                                {column.canFilter && column.render("Filter")}
                            </div>
                            <div {...column.getSortByToggleProps()}>
                                {column.render("Header")}
                                {column.isSorted ? (column.isSortedDesc ?
                                    <FontAwesomeIcon icon="sort-down"/> :
                                    <FontAwesomeIcon icon="sort-up"/>)
                                    : ""}
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}

export function ScrollableBodyVariableSize({
                                               bodyHeight, bodyWidth, getTableBodyProps, renderRow, listRef, rows,
                                               rowHeights
                                           }) {
    return (
        <div {...getTableBodyProps()} className="tbody" style={{display: "flex", height: bodyHeight}}>
            <AutoSizer>
                {({height}) => (
                    <VariableSizeList ref={listRef} height={height} itemCount={rows.length}
                                      itemSize={(index) => rowHeights[index]} width={bodyWidth}>
                        {renderRow}
                    </VariableSizeList>
                )}
            </AutoSizer>
        </div>
    );
}

export function BootstrapScrollableBodyTable({
                                                 getTableProps, headerGroups, bodyHeight, bodyWidth, getTableBodyProps,
                                                 renderRow, listRef, rows, rowHeights
                                             }) {
    return (
        <Styles>
            <div {...getTableProps()} className="table table-hover table-responsive table-sm">
                <Header headerGroups={headerGroups}/>

                <ScrollableBodyVariableSize
                    bodyHeight={bodyHeight} bodyWidth={bodyWidth} getTableBodyProps={getTableBodyProps}
                    renderRow={renderRow} listRef={listRef} rows={rows} rowHeights={rowHeights}/>
            </div>
        </Styles>
    );
}

const defaultPropGetter = () => ({});

export function StyledRow({index, style, rows, prepareRow, getCellProps = defaultPropGetter}) {
    const row = rows[index];
    prepareRow(row);

    return (
        <div key={index} {...row.getRowProps({style})} className="tr">
            {row.cells.map((cell, j) =>
                <div key={j} {...cell.getCellProps(getCellProps(cell))} className="td">
                    {cell.render("Cell")}
                </div>
            )}
        </div>
    );
}