import React, {useCallback, useEffect, useState} from "react";
import {getFarmTreatmentAnimalOptions, getTreatmentOptions, postTreatment} from "api/rest";
import {ErrorMessage, Field, Form as FormikForm, Formik} from "formik";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import * as Yup from "yup";
import Col from "react-bootstrap/Col";
import {FormikDatetime, FormikSelect} from "components";
import Table from "react-bootstrap/Table";
import Modal from "react-bootstrap/Modal";
import {parseCSSText} from "../../../utils/utils";

const validationSchema = Yup.object({
    date_time: Yup.date().required("Required."),
    treatment: Yup.array().of(Yup.number()).required("Required."),
    dose: Yup.number().positive("Dose needs to be positive.")
        .integer("Dose needs to be a whole number.").required("Required."),
    reason: Yup.string().max(255),
    comment: Yup.string().max(255),
    unreliable: Yup.array().of(Yup.number()),
    unreliable_reason: Yup.string().max(255),
    note: Yup.string().max(255)
});

function ModalBodyTable({idToInfo, ...props}) {
    return Object.values(idToInfo).length !== 0 ?
        <Table striped hover responsive size="sm">
            <thead>
            <tr>
                <th>Animal id.</th>
                <th>Tag</th>
                <th>Last arrival date</th>
                <th>Last departure date</th>
                <th>First calving date</th>
            </tr>
            </thead>
            <tbody>
            {
                Object.values(idToInfo).map((info) =>
                    <tr key={info["identifier"]}>
                        <td>{info["identifier"]}</td>
                        <td>{info["management_tag"]}</td>
                        <td>{info["arrival_date"]}</td>
                        <td>{info["departure_date"]}</td>
                        <td>{info["first_calving_date"]}</td>
                    </tr>)
            }
            </tbody>
        </Table> : "";
}

function TreatmentForm({refreshTreatments, ...props}) {
    const [treatmentOptions, setTreatmentOptions] = useState({
        treatment_types: [],
        treatment_reasons: [],
        unreliable_choices: []
    });
    const [animalOptions, setAnimalOptions] = useState({
        animals_at_farm: [],
        animals_left_farm: [],
        animals_ever_at_farm: []
    });
    const [showModal, setShowModal] = useState(false);
    const [modalTable, setModalTable] = useState(null);
    const [submitResults, setSubmitResults] = useState([]);
    const [showAnimalSelects, setShowAnimalSelects] = useState(false);

    const getAndSetTreatmentOptions = useCallback(() => {
        getTreatmentOptions()
            .then((data) => setTreatmentOptions(data.hasOwnProperty("results") ? data.results : data))
            .catch((error) => console.log(error.toString()));
    }, []);
    const getAndSetAnimalOptions = (id) => {
        getFarmTreatmentAnimalOptions(id)
            .then((data) => setAnimalOptions(data.hasOwnProperty("results") ? data.results : data))
            .catch((error) => console.log(error.toString()));
    };

    const toggleShow = () => setShowModal(!showModal);
    const submitTreatment = (values, actions) => {
        setModalTable(null);
        setSubmitResults([]);
        const submitResults = [];
        const postBody = {
                farm: parseInt(props.id),
                date_time: values.date_time,
                animal_detail: values.at_animal_detail !== "" ? parseInt(values.at_animal_detail) :
                    values.left_animal_detail !== "" ? parseInt(values.left_animal_detail) : null,
                management_tag: (values.at_animal_detail === "" && values.left_animal_detail === "") ?
                    values.management_tag : null,
                dose: values.dose !== "" ? parseInt(values.dose) : null,
                reason: values.reason !== "" ? parseInt(values.reason) : null,
                comment: values.comment,
                unreliable: values.unreliable,
                unreliable_reason: values.unreliable_reason,
                note: values.note
            };

        Promise.all(values.treatment.map((t, i) => {
            postBody["treatment"] = t !== "" ? parseInt(t) : null;
            if (i !== 0) postBody["comment"] = "";  // Don't want comments after the first treatment

            return postTreatment(postBody).then((data) => {
                if (data.hasOwnProperty("id_to_info")) setModalTable(<ModalBodyTable idToInfo={data["id_to_info"]}/>);

                const title = data.hasOwnProperty("animal") ?
                    "Successfully created a new treatment for: " + data["animal"]["identifier"]["identifier_type_id"] :
                    "Successfully created a new treatment!";
                submitResults.push(<div key={i} className="text-success">{treatmentTypes[t]}: {title}</div>);
            }).catch((error) => {
                console.log(error.toString());
                if (error.response.headers["content-type"] === "text/html")
                    submitResults.push(
                        <div key={i} className="text-danger">{treatmentTypes[t]}: {error.toString()}</div>);
                else if (error.response.headers["content-type"].endsWith("/json")) {
                    const errorMessage = [];

                    Object.keys(error.response.data).forEach((field) => {
                        if (field === "id_to_info")
                            setModalTable(<ModalBodyTable idToInfo={error.response.data["id_to_info"]}/>);
                        else if (field === "non_field_errors")
                            errorMessage.push(error.response.data["non_field_errors"]);
                        else {
                            actions.setFieldError(field, error.response.data[field]);
                            errorMessage.push(error.response.data[field]);
                        }
                    });

                    if (errorMessage.length !== 0) {
                        submitResults.push(<div key={i} className="text-danger">
                            {treatmentTypes[t]}: {errorMessage.join(" - ")}
                        </div>);
                    }
                }
            });
        })).then((data) => {
            setSubmitResults(submitResults);

            if (!showModal) toggleShow();

            refreshTreatments();
        }).catch((error) => console.log(error.toString()));

        actions.setFieldValue("comment", "");
        actions.setFieldValue("unreliable_reason", "");
        actions.setFieldValue("note", "");
    };

    useEffect(() => getAndSetTreatmentOptions(), [getAndSetTreatmentOptions]);
    useEffect(() => getAndSetAnimalOptions(props.id), [props.id]);

    const atAnimalDetailOptions = animalOptions.animals_at_farm.map((animal) => ({
        value: animal.identifier.id, label: animal.identifier.identifier_type_id + ", " + animal.management_tag
    }));
    const leftAnimalDetailOptions = animalOptions.animals_left_farm.map((animal) => ({
        value: animal.identifier.id, label: animal.identifier.identifier_type_id + ", " + animal.management_tag
    }));

    const treatmentTypeOptions = treatmentOptions.treatment_types.map((treatmentType) => ({
        label: treatmentType.name, value: treatmentType.id, group: treatmentType.category.name
    }));
    const treatmentTypes = treatmentOptions.treatment_types.reduce((result, treatmentType) => {
        result[treatmentType.id] = treatmentType.name;
        return result;
    }, {});
    const treatmentTypeSelect = <FormikSelect label="Treatment: " name="treatment" options={treatmentTypeOptions}
                                              isMulti useGroup/>;

    const treatmentReasonOptions = treatmentOptions.treatment_reasons.map((treatmentReason) => ({
        label: treatmentReason.name, value: treatmentReason.id, group: treatmentReason.category.name
    }));

    const unreliableOptions = treatmentOptions.unreliable_choices.map((choice) => ({
        value: choice[0], label: choice[1]
    }));
    const unreliableSelect = <FormikSelect label="Unreliable: " name="unreliable" options={unreliableOptions} isMulti/>;

    const initialValues = {
        date_time: "", at_animal_detail: "", left_animal_detail: "", management_tag: "", treatment: "", dose: 1,
        reason: "", comment: "", unreliable: [], unreliable_reason: "", note: ""
    };

    return (
        <div>
            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={submitTreatment}>
                {formik => (
                    <FormikForm as={Form} noValidate>
                        <Form.Row>
                            <Col sm>
                                <FormikDatetime label="Datetime: " name="date_time"/>
                            </Col>
                            <Form.Group as={Col} sm controlId="management_tag">
                                <Form.Label>Tag: </Form.Label>
                                <Field as={Form.Control} name="management_tag" type="text"
                                       disabled={formik.values.at_animal_detail !== "" ||
                                       formik.values.left_animal_detail !== ""}/>
                                <Form.Check type="checkbox" label="Know animal number?"
                                            onChange={() => {
                                                setShowAnimalSelects(!showAnimalSelects);
                                                formik.setFieldValue("management_tag", "");
                                                formik.setFieldValue("at_animal_detail", "");
                                                formik.setFieldValue("left_animal_detail", "");
                                            }}/>
                                <ErrorMessage name="management_tag" component="span" className="text-danger"/>
                            </Form.Group>
                            <Col sm>
                                {treatmentTypeSelect}
                            </Col>
                            <Col sm>
                                <Form.Group controlId="dose">
                                    <Form.Label>Dose: </Form.Label>
                                    <Field as={Form.Control} name="dose" type="number" min={1}/>
                                    <ErrorMessage name="dose" component="span" className="text-danger"/>
                                </Form.Group>
                            </Col>
                        </Form.Row>
                        {
                            showAnimalSelects ? (
                                <Form.Row>
                                    <Col sm>
                                        <FormikSelect label="At farm: " name="at_animal_detail"
                                                      options={atAnimalDetailOptions}
                                                      isDisabled={
                                                          formik.values.left_animal_detail !== "" ||
                                                          formik.values.management_tag !== ""}/>
                                    </Col>
                                    <Col sm>
                                        <FormikSelect label="Left farm: " name="left_animal_detail"
                                                      options={leftAnimalDetailOptions}
                                                      isDisabled={
                                                          formik.values.at_animal_detail !== "" ||
                                                          formik.values.management_tag !== ""}/>
                                    </Col>
                                </Form.Row>
                            ) : ""
                        }
                        <Form.Row>
                            <Form.Group as={Col} sm controlId="comment">
                                <Form.Label>Comment: </Form.Label>
                                <Field as={Form.Control} name="comment" type="text"/>
                                <ErrorMessage name="comment" component="span" className="text-danger"/>
                                <Form.Text muted>Not required.</Form.Text>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} sm controlId="reason">
                                <FormikSelect label="Reason: " name="reason" options={treatmentReasonOptions}
                                              useGroup/>
                                <Form.Text muted>Not required.</Form.Text>
                            </Form.Group>
                            <Form.Group as={Col} sm controlId="note">
                                <Form.Label>Note: </Form.Label>
                                <Field as={Form.Control} name="note" type="text"/>
                                <ErrorMessage name="note" component="span" className="text-danger"/>
                                <Form.Text muted>Not required.</Form.Text>
                            </Form.Group>
                            <Col sm>
                                {unreliableSelect}
                                <Form.Text muted>Not required.</Form.Text>
                            </Col>
                            <Form.Group as={Col} sm controlId="unreliable_reason">
                                <Form.Label>Unreliable reason: </Form.Label>
                                <Field as={Form.Control} name="unreliable_reason" type="text"/>
                                <ErrorMessage name="unreliable_reason" component="span" className="text-danger"/>
                                <Form.Text muted>
                                    {formik.values.unreliable.length === 0 ? "Not required." : "Required."}
                                </Form.Text>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Col>
                                <Button className="mt-3" type="submit">Submit</Button>
                            </Col>
                        </Form.Row>
                    </FormikForm>
                )}
            </Formik>
            <Modal show={showModal} onHide={toggleShow} animation={false} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Results</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {submitResults}
                    {modalTable ? modalTable : ""}
                </Modal.Body>
            </Modal>
        </div>
    );
}

export default TreatmentForm;