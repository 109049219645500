import React, {useEffect, useState} from "react";
import {UserContext} from "contexts";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import LoginPage from "pages/LoginPage";
import {
    AggregatedResearch,
    AnimalsResearch,
    ConsultantAnalysis,
    Dashboard,
    Farms,
    TreatedSCCEvolution,
    TreatmentAnalysis
} from "pages/farms";
import {logoutUser} from "api/auth";
import {Link, Route, Switch} from "react-router-dom";
import {library} from "@fortawesome/fontawesome-svg-core";
import {
    faArrowRight,
    faFileExport,
    faPlus,
    faSignOutAlt,
    faSortDown,
    faSortUp,
    faTrashAlt,
    faMinus,
} from "@fortawesome/free-solid-svg-icons"
import FarmTreatmentsPage from "pages/FarmTreatmentsPage";
import AddTreatmentPage from "pages/AddTreatmentPage";
import "react-datetime/css/react-datetime.css";
import TestPage from "pages/TestPage";
import FarmResearchPage from "pages/FarmResearchPage";
import {ProtectedRoute} from "components";
import ProfilePage from "pages/ProfilePage";
import {ROITool} from "pages/roi_tool";
import {isMobileOnly} from "react-device-detect";
import "react-tabs/style/react-tabs.css";
import Modal from "react-bootstrap/Modal";

library.add(faArrowRight, faPlus, faSignOutAlt, faTrashAlt, faSortUp, faSortDown, faFileExport, faMinus);

function App() {
    const [userDetails, setUserDetails] = useState(JSON.parse(window.localStorage.getItem("ahv_user")));
    const [showModal, setShowModal] = useState(false);
    const isUserLoggedIn = () =>
        !!userDetails && userDetails.hasOwnProperty("user") && userDetails["user"].hasOwnProperty("groups");
    const inUserGroups = (groups) => {
        // Default if groups has not been defined, allow access.
        return !!groups && !!userDetails ?
            userDetails["user"]["groups"].some((group) => groups.indexOf(group.toUpperCase()) !== -1) :
            true;
    };
    const logout = (event) => {
        event.preventDefault();
        logoutUser();
        setUserDetails(null);
    };

    useEffect(() => {
        if (!!userDetails && userDetails.hasOwnProperty("user")) setShowModal(true);
    }, [userDetails]);

    return (
        <div className="App">
            <UserContext.Provider value={{userDetails, setUserDetails, isUserLoggedIn, inUserGroups}}>
                <Container fluid>
                    <div className="row">
                        <div className={isMobileOnly ? "col-12" : "col-10 offset-1"}>
                            <Navbar bg="light" expand="lg" className="mb-3 shadow">
                                <Navbar.Brand as={Link} to="/">AHV</Navbar.Brand>
                                <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                                <Navbar.Collapse id="basic-navbar-nav">
                                    <Nav className="mr-auto">
                                        {
                                            isUserLoggedIn() &&
                                            <Nav.Item><Nav.Link as={Link} to="/farms">Farms</Nav.Link></Nav.Item>
                                        }
                                        {
                                            isUserLoggedIn() && inUserGroups(["ADMIN", "RESEARCHER", "ROI_TOOL"]) &&
                                            <Nav.Item><Nav.Link as={Link} to="/roi_tool">ROI Tool</Nav.Link></Nav.Item>
                                        }
                                    </Nav>
                                    <Nav className="justify-content-end">
                                        {
                                            isUserLoggedIn() &&
                                            <Nav.Item><Nav.Link as={Link} to="/profile">Profile</Nav.Link></Nav.Item>
                                        }
                                        {
                                            isUserLoggedIn() &&
                                            <Nav.Item><Nav.Link as={Link} to="/"
                                                                onClick={logout}>Logout</Nav.Link></Nav.Item>
                                        }
                                        {
                                            !isUserLoggedIn() &&
                                            <Nav.Item><Nav.Link as={Link} to="/login">Login</Nav.Link></Nav.Item>
                                        }
                                    </Nav>
                                </Navbar.Collapse>
                            </Navbar>
                            <Modal show={showModal} onHide={() => setShowModal(false)} backdrop="static"
                                   keyboard={false} centered size="lg">
                                <Modal.Header closeButton>
                                    <Modal.Title>Attentie, deze dienst gaat stoppen.</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    Deze app wordt binnenkort niet meer ondersteund. Deze dienst zal offline gehaald
                                    worden. Data-analyse van al je klanten is mogelijk via de AHV Farm App.<br/><br/>

                                    Neem contact op met je manager bij vragen of onduidelijkheden.
                                </Modal.Body>
                            </Modal>
                            <Switch>
                                <ProtectedRoute path="/farms/:id/treatments/add" component={AddTreatmentPage}/>
                                <ProtectedRoute path="/farms/:id/treatment_analysis"
                                                groups={["ADMIN", "RESEARCHER"]} component={TreatmentAnalysis}/>
                                <ProtectedRoute path="/farms/:id/aggregated_research" component={AggregatedResearch}/>
                                <ProtectedRoute path="/farms/:id/animals_research" component={AnimalsResearch}/>
                                <ProtectedRoute path="/farms/:id/treatments" component={FarmTreatmentsPage}/>
                                <ProtectedRoute path="/farms/:id/ca" component={ConsultantAnalysis}/>
                                <ProtectedRoute path="/farms/:id/treated_scc_evolution" component={TreatedSCCEvolution}
                                                groups={["ADMIN", "RESEARCHER"]}/>
                                <ProtectedRoute path="/farms/:id/individual_research" component={FarmResearchPage}/>
                                <ProtectedRoute path="/farms/:id" component={Dashboard}/>
                                <ProtectedRoute path="/farms" component={Farms}/>
                                <ProtectedRoute path="/roi_tool" component={ROITool}
                                                groups={["ADMIN", "RESEARCHER", "ROI_TOOL"]}/>
                                <ProtectedRoute path="/test" component={TestPage}/>
                                <Route exact path="/login" component={LoginPage}/>
                                <ProtectedRoute path="/profile" component={ProfilePage}/>
                                <ProtectedRoute path="/" component={Farms}/>
                            </Switch>
                        </div>
                    </div>
                </Container>
            </UserContext.Provider>
        </div>
    );
}

export default App;
