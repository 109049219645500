import React, {useEffect, useState} from "react";
import Form from "react-bootstrap/Form";

export function TextFilterDebounced({value, onChange, debounceTime, placeholder}) {
    const [text, setText] = useState(value);

    useEffect(() => {
        const timer = setTimeout(() => onChange(text), debounceTime);

        return () => clearTimeout(timer);
    }, [text, onChange, debounceTime]);

    return (
        <Form.Control
            value={text}
            type="text"
            size="sm"
            placeholder={placeholder}
            onChange={e => setText(e.target.value)}
        />
    );
}