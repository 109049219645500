import React from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";

function FromToInput({label, min, max, fromValue, toValue, handleFromChange, handleToChange}) {
    return (
        <div>
            <Form.Row>
                <Form.Group as={Col}>
                    <InputGroup>
                        <InputGroup.Prepend>
                            <InputGroup.Text>{label}</InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control type="text" defaultValue={fromValue} placeholder={fromValue}
                                      onChange={(event) => handleFromChange(event.target.value)}/>
                        <InputGroup.Append>
                            <InputGroup.Text>{">=" + min.toString()}</InputGroup.Text>
                        </InputGroup.Append>
                    </InputGroup>
                </Form.Group>
            </Form.Row>
            <Form.Row>
                <Form.Group as={Col}>
                    <InputGroup>
                        <InputGroup.Prepend>
                            <InputGroup.Text>{label}</InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control type="text" defaultValue={toValue} placeholder={toValue}
                                      onChange={(event) => handleToChange(event.target.value)}/>
                        <InputGroup.Append>
                            <InputGroup.Text>{"<=" + max.toString()}</InputGroup.Text>
                        </InputGroup.Append>
                    </InputGroup>
                </Form.Group>
            </Form.Row>
        </div>
    );
}

export default FromToInput;