import React, {useCallback, useContext, useEffect} from "react";
import {Redirect, Route, useHistory} from "react-router-dom";
import {UserContext} from "contexts";
import {logoutUser} from "api/auth";

function ProtectedRoute({groups, ...props}) {
    const history = useHistory();
    const {userDetails, setUserDetails, isUserLoggedIn, inUserGroups} = useContext(UserContext);

    const redirectUnauthenticatedUser = useCallback(() => {
        if (!isUserLoggedIn()) {
            logoutUser();
            setUserDetails(null);
            history.push("/login?next=" + history.location.pathname);
        }
    }, [history, isUserLoggedIn, setUserDetails]);

    useEffect(() => redirectUnauthenticatedUser(), [userDetails, redirectUnauthenticatedUser]);

    return inUserGroups(groups) ? <Route {...props}/> : <Redirect to="/"/>;
}

export default ProtectedRoute;