import React from "react";
import Form from "react-bootstrap/Form";

export function DefaultColumnFilter({column: {filterValue, setFilter}}) {
    return (
        <Form.Control
            value={filterValue || ""}
            onChange={(e) => setFilter(e.target.value || undefined)}
            size="sm"
        />
    );
}
