import React, {useCallback, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {useFarmTitle} from "states";
import {getTreatedSCCEvolution} from "api/rest";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {FarmNavigationButtonGroup} from "components";
import {PercentageStackedBarChart} from "components/BarChart";
import {sum} from "lodash";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";

export function TreatedSCCEvolution() {
    const {id} = useParams();
    const {farmTitle} = useFarmTitle();

    const [message, setMessage] = useState("");
    const [dataset, setDataset] = useState({count: 0, data: []});
    const [datasetLatent, setDatasetLatent] = useState({count: 0, data: []});
    const [datasetPersistent, setDatasetPersistent] = useState({count: 0, data: []});
    const [datasetPersistentChronic, setDatasetPersistentChronic] = useState({count: 0, data: []});
    const [currentDataset, setCurrentDataset] = useState(dataset);

    const submitRequestDataset = useCallback((id) => {
        setMessage("");
        getTreatedSCCEvolution(id)
            .then((data) => {
                Object.keys(data).forEach((t) => {
                    const labels = data[t]["columns"].map((arr) => arr[2]);
                    let totalCount = 0;
                    const result = data[t]["index"].map((mpr, i) => {
                        totalCount = sum(data[t]["data"][i]);
                        const result = labels.reduce((result, label, j) => {
                            result[label] = data[t]["data"][i][j] / totalCount * 100;

                            return result;
                        }, {});
                        result["mpr"] = mpr;

                        return result;
                    });
                    if (t === "all") {
                        setDataset({count: totalCount, data: result});
                        setCurrentDataset({count: totalCount, data: result});
                    } else if (t === "latent") setDatasetLatent({count: totalCount, data: result});
                    else if (t === "persistent") setDatasetPersistent({count: totalCount, data: result});
                    else if (t === "persistent chronic") setDatasetPersistentChronic({count: totalCount, data: result});
                });
            }).catch((error) => {
            if (error.response.data !== "" && error.response.data.hasOwnProperty("message"))
                setMessage(error.response.data.message);
            else console.log(error.toString());
        });
    }, []);

    useEffect(() => submitRequestDataset(id), [id, submitRequestDataset]);

    return (
        <div className="shadow">
            <Row className="justify-content-between pt-3 pl-3 pr-3">
                <Col md="auto">
                    <h4>{farmTitle}</h4>
                </Col>
                <Col md="auto">
                    <FarmNavigationButtonGroup id={id}/>
                </Col>
            </Row>
            <Row className="pl-3 pr-3 pb-3">
                <Col md="auto">
                    <h4>Treated SCC Evolution</h4>
                </Col>
            </Row>
            <Row className="pl-3 pr-3 pb-3 justify-content-between">
                <Col md="auto" className="align-self-start">
                    <div className="font-weight-bold">
                        # Animals {currentDataset["count"]}
                    </div>
                </Col>
                <Col md="auto">
                    <InputGroup>
                        <InputGroup.Prepend>
                            <InputGroup.Text>
                                Category filter
                            </InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control as="select" defaultValue="all"
                                      onChange={(event) => {
                                          if (event.target.value === "all") setCurrentDataset(dataset);
                                          else if (event.target.value === "latent") setCurrentDataset(datasetLatent);
                                          else if (event.target.value === "persistent")
                                              setCurrentDataset(datasetPersistent);
                                          else if (event.target.value === "persistent chronic")
                                              setCurrentDataset(datasetPersistentChronic);
                                      }}>
                            <option value="all">All</option>
                            <option value="latent">Latent</option>
                            <option value="persistent">Persistent</option>
                            <option value="persistent chronic">Persistent Chronic</option>
                        </Form.Control>
                    </InputGroup>
                </Col>
            </Row>
            {
                currentDataset["data"].length !== 0 &&
                <Row className="pl-3 pr-3" style={{minHeight: "72vh"}}>
                    <Col>
                        <PercentageStackedBarChart
                            dataset={currentDataset["data"]}
                            xAxisKey="mpr"
                            xAxisLabel="MPR"
                            yAxisLabel="SCC Bin Count %"
                            stackId="treated-scc-evolution"
                        />
                    </Col>
                </Row>
            }
            <Row className="justify-content-center">
                <Col md="auto">
                    <b>{message}</b>
                </Col>
            </Row>
        </div>
    );
}