import React, {useCallback, useEffect, useRef, useState} from "react";
import {useParams} from "react-router-dom";
import {useFarmTitle} from "states";
import {getConsultantAnalysis} from "api/rest";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {FarmNavigationButtonGroup} from "components";
import {max, min} from "lodash";
import {parseCSSText} from "utils/utils";
import {BootstrapScrollableBodyTable, StyledRow} from "components/ReactTableDiv";
import {HideColumnOverlay, useSortableFilterableTable} from "components/ReactTableBasic";
import {DefaultColumnFilter} from "components/TableFilters";
import Card from "react-bootstrap/Card";
import Badge from "react-bootstrap/Badge";
import Popover from "react-bootstrap/Popover";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Button from "react-bootstrap/Button";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";

export function ConsultantAnalysis() {
    const {id} = useParams();
    const {farmTitle} = useFarmTitle();

    const [message, setMessage] = useState("");
    const [dataset, setDataset] = useState([]);
    const [departedDataset, setDepartedDataset] = useState([]);

    const submitRequestDataset = useCallback((id) => {
        setMessage("");
        getConsultantAnalysis(id, ["max_year=2019", "min_mpr_cows=5"])
            .then((data) => {
                const nonDeparted = [];
                const departed = [];
                data.forEach((r) => {
                    if (r["status"] === "Departed") departed.push(r);
                    else nonDeparted.push(r);
                });
                setDataset(nonDeparted);
                setDepartedDataset(departed);
            })
            .catch((error) => {
                if (error.response.data !== "" && error.response.data.hasOwnProperty("message"))
                    setMessage(error.response.data.message);
                else console.log(error.toString());
            });
    }, []);

    useEffect(() => submitRequestDataset(id), [id, submitRequestDataset]);

    return (
        <div className="shadow">
            <Row className="justify-content-between pt-3 pl-3 pr-3">
                <Col md="auto">
                    <h4>{farmTitle}</h4>
                </Col>
                <Col md="auto">
                    <FarmNavigationButtonGroup id={id}/>
                </Col>
            </Row>
            <Row className="pl-3 pr-3 pb-3">
                <Col md="auto">
                    <h4>Consultant Analysis</h4>
                </Col>
            </Row>
            <Row className="pl-3 pr-3">
                <Col>
                    <Tabs>
                        <TabList>
                            <Tab>Not departed</Tab>
                            <Tab>Departed</Tab>
                        </TabList>

                        <TabPanel>
                            {dataset.length !== 0 && <ConsultantAnalysisTable dataset={dataset}/>}
                        </TabPanel>

                        <TabPanel>
                            {departedDataset.length !== 0 && <ConsultantAnalysisTable dataset={departedDataset}/>}
                        </TabPanel>
                    </Tabs>
                </Col>
            </Row>
            <Row className="justify-content-center">
                <Col md="auto">
                    <b>{message}</b>
                </Col>
            </Row>
        </div>
    );
}

function findLongestLength(dataset, column) {
    return max([
        column.length,
        max(dataset.map((row) => !!row[column] ? max(row[column].split("\n").map((line) => line.length)) : 0))])
}

function formatStringIntoCell(value) {
    return value ?
        <div>{String(value).split("\n").map((line, i) => <div key={i}>{line}</div>)}</div> : ""
}

function isMpr(value) {
    return value ? (value.length === 10 && value.includes("-")) : false;
}

function ConsultantAnalysisTable({dataset}) {
    // E.g. styles[rowIndex][column_style]
    const styles = React.useMemo(() => {
        const styleColumns = Object.keys(dataset[0]).filter((c) => c.endsWith("_style"));
        return dataset.reduce((rowResult, row, i) => {
            rowResult[i] = styleColumns.reduce((result, style) => {
                result[style] = row[style] ? parseCSSText(row[style])["style"] : {};
                return result;
            }, {});
            return rowResult;
        }, {});
    }, [dataset]);

    const columns = React.useMemo(() => {
        const columns = [
            {Header: "Animal number", accessor: "animal_number", width: 120},
            {Header: "Tag", accessor: "tag", width: 70},
            {Header: "Status", accessor: "status", width: 70},
            {
                Header: "Treatments", accessor: "treatments",
                width: max([80, findLongestLength(dataset, "treatments") * 6.6]),
                Cell: ({value}) => formatStringIntoCell(value)
            },
            {
                Header: "Comments", accessor: "comments",
                width: max([80, findLongestLength(dataset, "comments") * 6.4]),
                Cell: ({value}) => formatStringIntoCell(value)
            },
            {
                Header: "Reasons", accessor: "reasons",
                width: max([70, findLongestLength(dataset, "reasons") * 6.6]),
                Cell: ({value}) => formatStringIntoCell(value),
                show: false
            },
            {Header: "Expected milk yield", accessor: "expected_milk_yield", width: 80},
            {Header: "Milk yield", accessor: "milk_yield", width: 80},
            {Header: "Fat %", accessor: "fat_%", width: 80},
            {Header: "Protein %", accessor: "protein_%", width: 80},
            {Header: "Lactose %", accessor: "lactose_%", width: 80},
            {Header: "Urea", accessor: "urea", width: 80},
            {Header: "Lactation length", accessor: "lactation_length", width: 80},
            {Header: "Inseminations in current lactation", accessor: "inseminations", width: 95},
            {Header: "Elevations", accessor: "elevations", width: 80},
            {Header: "Ketosis", accessor: "ketosis", width: 80},
            {Header: "Lactation value", accessor: "lactation_value", width: 80},
            {Header: "Lactation", accessor: "lactation", width: 80},
            {
                Header: "Booster advice", accessor: "booster_advice",
                width: findLongestLength(dataset, "booster_advice") * 7.5,
                Cell: ({value}) => formatStringIntoCell(value)
            },
            {
                Header: "Booster", accessor: "booster", width: findLongestLength(dataset, "booster") * 7.5,
                show: false
            },
            {
                Header: "Booster analyse", accessor: "booster_analysis",
                width: findLongestLength(dataset, "booster_analysis") * 6.6,
                show: false
            },
            {
                Header: "AHV advice", accessor: "ahv_advice",
                width: max([90, findLongestLength(dataset, "ahv_advice") * 6.6])
            },
            {Header: "Expected dry off date", accessor: "expected_dry_off_date", width: 90},
            {
                Header: "Category", accessor: "category",
                width: max([80, findLongestLength(dataset, "category") * 6.7]),
                Cell: ({value}) => {
                    switch (value) {
                        case "Clear":
                            return formatStringIntoCell("UDR-C");
                        case "Latent":
                            return formatStringIntoCell("UDR-L");
                        case "Persistent":
                            return formatStringIntoCell("UDR-P");
                        case "Persistent Chronic":
                            return formatStringIntoCell("UDR-R");
                        default:
                            return formatStringIntoCell(value);
                    }
                }
            }
        ];

        const mprColumns = Object.keys(dataset[0])
            .filter((c) => isMpr(c))
            .filter((c) => dataset.filter((row) => !!row[c]).length > 0)
            .map((accessor) => ({Header: accessor, accessor: accessor, width: 95}));
        columns.push(...mprColumns);

        return columns;
    }, [dataset]);

    return (
        <CATable columns={columns} data={dataset}
                 getCellProps={cell => ({style: styles[cell.row.index][cell.column.id + "_style"]})}/>
    )
}

function CATable({columns, data, getCellProps}) {
    const listRef = useRef();
    const defaultColumn = React.useMemo(() => ({
        Filter: DefaultColumnFilter,
        filter: "advanced"
    }), []);

    const {
        getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, allColumns, totalColumnsWidth
    } = useSortableFilterableTable(columns, data, defaultColumn);

    const renderRow = React.useCallback(({index, style}) =>
            <StyledRow index={index} style={style} rows={rows} prepareRow={prepareRow} getCellProps={getCellProps}/>,
        [prepareRow, rows, getCellProps]);

    const rowHeights = React.useMemo(() => {
        // Everytime the rows changes because of filtering for example, we need to reset the row heights.
        if (listRef.current) listRef.current.resetAfterIndex(0, false);

        return rows.map((row) => {
            let multiplier = 1;
            if (row.original["treatments"]) multiplier = row.original["treatments"].split("\n").length;
            if (row.original["status"])
                multiplier = max([multiplier, min([3,
                    row.original["status"].length > 10 ? row.original["status"].split(" ").length : 1])]);
            if (row.original["booster_advice"])
                multiplier = max([multiplier, row.original["booster_advice"].split("\n").length]);

            return multiplier * 25;
        });
    }, [rows]);

    return (
        <>
            <CAHeader rows={rows} columns={allColumns}/>
            <small>
                <BootstrapScrollableBodyTable
                    getTableProps={getTableProps} headerGroups={headerGroups} bodyHeight="60vh"
                    bodyWidth={totalColumnsWidth} getTableBodyProps={getTableBodyProps} renderRow={renderRow}
                    listRef={listRef} rows={rows} rowHeights={rowHeights}/>
            </small>
        </>
    );
}

function OperatorHelp({operator, helpText}) {
    return (
        <Row>
            <Col className="col-3 font-weight-bold">{operator}</Col>
            <Col>{helpText}</Col>
        </Row>
    )
}

function CAHeader({rows, columns}) {
    const helpPopover = (
        <Popover id="column-filter-help">
            <Popover.Content>
                Use the filters above each column to filter and limit table data. Advanced searches can be performed by
                using the following operators:<br/>
                <OperatorHelp operator={"<"} helpText={"Lower than"}/>
                <OperatorHelp operator={"<="} helpText={"Lower than or equal"}/>
                <OperatorHelp operator={">"} helpText={"Greater than"}/>
                <OperatorHelp operator={">="} helpText={"Greater than or equal"}/>
                <OperatorHelp operator={"="} helpText={"Exact"}/>
                <OperatorHelp operator={"*"} helpText={"Partial"}/>
                <OperatorHelp operator={"!"} helpText={"Different"}/>
                <OperatorHelp operator={"{"} helpText={"Starts with"}/>
                <OperatorHelp operator={"}"} helpText={"Ends with"}/>
                <OperatorHelp operator={"||"} helpText={"Contains at least one of"}/>
                <OperatorHelp operator={"&&"} helpText={"Contains all of"}/>
                <OperatorHelp operator={"rgx:"} helpText={"Regex"}/>
            </Popover.Content>
        </Popover>
    );

    return (
        <Card>
            <Card.Header className="pt-1 pb-1">
                <Row className="justify-content-between">
                    <Col md="auto" className="align-self-center">
                        <div className="font-weight-bold" style={{fontSize: "0.9rem"}}>
                            # Animals {rows.length}
                        </div>
                    </Col>
                    <Col>
                        <Row className="justify-content-end">
                            <Col md="auto" className="pr-1">
                                <HideColumnOverlay id="ca-hide-columns-overlay"
                                                   columns={columns.filter((c) => !isMpr(c.id))}/>
                            </Col>
                            <Col md="auto" className="pl-1 pr-1">
                                <OverlayTrigger trigger="click" rootClose placement="bottom" overlay={helpPopover}>
                                    <Badge as={Button} pill variant="outline-primary">?</Badge>
                                </OverlayTrigger>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Card.Header>
        </Card>
    );
}