import React from "react";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import {Typeahead} from "react-bootstrap-typeahead";

function InputGroupPrepend({prependLabel}) {
    return (
        <InputGroup.Prepend>
            <InputGroup.Text>
                {prependLabel}
            </InputGroup.Text>
        </InputGroup.Prepend>
    )
}

function InputGroupAppend({appendLabel}) {
    return (
        <InputGroup.Append>
            <InputGroup.Text>
                {appendLabel}
            </InputGroup.Text>
        </InputGroup.Append>
    )
}

export function NumberInputGroup({prependLabel, appendLabel, defaultValue, onChange}) {
    return (
        <InputGroup>
            {!!prependLabel && <InputGroupPrepend prependLabel={prependLabel}/>}
            <Form.Control
                type="number"
                value={defaultValue}
                onChange={onChange}
            />
            {!!appendLabel && <InputGroupAppend appendLabel={appendLabel}/>}
        </InputGroup>
    );
}

export function SelectInputGroup({prependLabel, appendLabel, defaultValue, onChange, options, isMultiple}) {
    return (
        <InputGroup>
            {!!prependLabel && <InputGroupPrepend prependLabel={prependLabel}/>}
            <Typeahead
                id={"typeahead-" + (!!prependLabel ? (prependLabel + "-") : "") + (!!appendLabel ? appendLabel : "")}
                defaultSelected={defaultValue}
                onChange={onChange}
                options={options}
                multiple={!!isMultiple}
                maxResults={999999999}
                clearButton
            />
            {!!appendLabel && <InputGroupAppend appendLabel={appendLabel}/>}
        </InputGroup>
    )
}
