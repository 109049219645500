import {useEffect, useState} from "react";
import {getFarm} from "api/rest";
import {useParams} from "react-router-dom";

const useFarmTitle = () => {
    const {id} = useParams();

    const [farmTitle, setFarmTitle] = useState("");

    const getFarmTitle = (farmId) => {
        getFarm(farmId)
            .then((data) => setFarmTitle("[" + data.location.scheme + "] " +
                data.location.identifier_type_id + ", " + data.surname + ", " + data.address))
            .catch((error) => console.log(error.toString()));
    };

    useEffect(() => getFarmTitle(id), [id]);

    return {farmTitle};
}

export default useFarmTitle;