import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useParams} from "react-router-dom";
import {getFarmResearchData} from "api/rest";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import {Form} from "react-bootstrap";
import * as Yup from "yup";
import {Form as FormikForm, Formik} from "formik";
import {FarmNavigationButtonGroup, FormikDate, InputSide} from "components";
import Button from "react-bootstrap/Button";
import moment from "moment";
import {useFarmTitle} from "states";

const validationSchema = Yup.object({
    monster_date_from: Yup.date().required("Required."),
    monster_date_to: Yup.date().required("Required."),
});

const today = moment();
const startOfYear = moment("2020-01-01");

function FarmResearchPage() {
    const {id} = useParams();
    const {farmTitle} = useFarmTitle();

    const [message, setMessage] = useState("");
    const [dataset, setDataset] = useState([]);

    const submitRequestDataset = useCallback((values, actions) => {
        setMessage("");
        getFarmResearchData(id, values.monster_date_from, values.monster_date_to)
            .then((data) => setDataset(data))
            .catch((error) => {
                if (error.response.data !== "" && error.response.data.hasOwnProperty("message"))
                    setMessage(error.response.data.message);
                else console.log(error.toString());
            });
    }, [id]);

    const initialValues = useMemo(() => ({
        monster_date_from: startOfYear.format("YYYY-MM-DD"), monster_date_to: today.format("YYYY-MM-DD")
    }), []);

    useEffect(() => submitRequestDataset(initialValues, null),
        [id, submitRequestDataset, initialValues]);

    return (
        <div className="shadow">
            <Row className="justify-content-between pt-3 pl-3 pr-3">
                <Col md="auto">
                    <h4>{farmTitle}</h4>
                </Col>
                <Col md="auto">
                    <FarmNavigationButtonGroup id={id}/>
                </Col>
            </Row>
            <Row className="pl-3 pr-3 pb-3">
                <Col md="auto">
                    <h4>Research</h4>
                </Col>
            </Row>
            <Row className="pl-3 pr-3 pb-3">
                <Col md="auto">
                    <Formik initialValues={initialValues} validationSchema={validationSchema}
                            onSubmit={submitRequestDataset}>
                        {formik => (
                            <FormikForm as={Form} noValidate>
                                <Form.Row>
                                    <Col>
                                        <FormikDate label="Monster date from " name="monster_date_from"
                                                    initialValue={startOfYear}/>
                                    </Col>
                                    <Col>
                                        <FormikDate label="to" name="monster_date_to" initialValue={today}/>
                                    </Col>
                                </Form.Row>
                                <Form.Row>
                                    <Col>
                                        <Button className="mt-3" type="submit">Submit</Button>
                                    </Col>
                                </Form.Row>
                            </FormikForm>
                        )}
                    </Formik>
                </Col>
            </Row>
            {dataset.length !== 0 ? <InputSide dataset={dataset}/> : ""}
            <Row className="justify-content-center">
                <Col md="auto">
                    <b>{message}</b>
                </Col>
            </Row>
        </div>
    );
}

export default FarmResearchPage;