import {escapeRegExp, trim} from "lodash";

export const groupBy = (objectArray, ...properties) => {
    return [...Object.values(objectArray.reduce((accumulator, object) => {
        const key = JSON.stringify(properties.map((x) => object[x] || null));

        if (!accumulator[key]) accumulator[key] = [];

        accumulator[key].push(object);
        return accumulator;
    }, {}))];
};

export const parseCSSText = (cssText) => {
    const cssTxt = cssText.replace(/\/\*(.|\s)*?\*\//g, " ").replace(/\s+/g, " ");
    const style = {}, [, ruleName, rule] = cssTxt.match(/ ?(.*?) ?{([^}]*)}/) || [undefined, undefined, cssTxt];
    const cssToJs = s => s.replace(/\W+\w/g, match => match.slice(-1).toUpperCase());
    const properties = rule.split(";").map(o => o.split(":").map(x => x && x.trim()));
    for (const [property, value] of properties) style[cssToJs(property)] = value;

    return {cssText, ruleName, style};
};

// Logic taken from https://github.com/koalyptus/TableFilter/blob/master/src/tablefilter.js
export const advancedFilter = (searchArgs, value) => {
    let sAOrSplit = searchArgs.toString().split("||"),
        hasMultiOr = sAOrSplit.length > 1,
        sAAndSplit = searchArgs.toString().split("&&"),
        hasMultiAnd = sAAndSplit.length > 1;

    if (hasMultiOr || hasMultiAnd) {
        let trimmedTerm;
        let found = false;

        const searchTerms = hasMultiOr ? sAOrSplit : sAAndSplit;
        // Isolate search terms and check occurrence
        for (let i = 0; i < searchTerms.length; i++) {
            trimmedTerm = trim(searchTerms[i]);
            found = match(trimmedTerm, value);

            if ((hasMultiOr && found) || (hasMultiAnd && !found)) break;
        }

        return found;
    } else return match(trim(searchArgs), value);
};

export const match = (term, value) => {
    let reLE = new RegExp("<="),
        reGE = new RegExp(">="),
        reLO = new RegExp("<"),
        reGR = new RegExp(">"),
        reDF = new RegExp("!"),
        reLK = new RegExp(escapeRegExp("*")),
        reEQ = new RegExp("="),
        reST = new RegExp("{"),
        reEN = new RegExp("}"),
        reRE = new RegExp(escapeRegExp("rgx:"));

    term = term.toLowerCase();
    let occurrence;
    let hasLE = reLE.test(term),
        hasGE = reGE.test(term),
        hasLO = reLO.test(term),
        hasGR = reGR.test(term),
        hasDF = reDF.test(term),
        hasLK = reLK.test(term),
        hasEQ = reEQ.test(term),
        hasST = reST.test(term),
        hasEN = reEN.test(term),
        hasRE = reRE.test(term);

    // Convert to number anyways to auto-resolve type in case not
    // defined by configuration. Order is important first try to
    // parse formatted number then fallback to Number coercion
    // to avoid false positives with Number
    const numData = parseNumber(value) || Number(value);

    if (hasRE) {
        try {
            const rgx = new RegExp(term.replace(reRE, ""));
            occurrence = rgx.test(value);
        } catch (ex) {
            occurrence = false;
        }
    } else if (hasLE) occurrence = numData <= parseNumber(term.replace(reLE, ""));
    else if (hasGE) occurrence = numData >= parseNumber(term.replace(reGE, ""));
    else if (hasLO) occurrence = numData < parseNumber(term.replace(reLO, ""));
    else if (hasGR) occurrence = numData > parseNumber(term.replace(reGR, ""));
    else if (hasDF) occurrence = !contains(term.replace(reDF, ""), value);
    else if (hasLK) occurrence = contains(term.replace(reLK, ""), value);
    else if (hasEQ) occurrence = contains(term.replace(reEQ, ""), value, true);
    else if (hasST) occurrence = value.indexOf(term.replace(reST, "")) === 0;
    else if (hasEN) {
        const searchArg = term.replace(reEN, "");
        const n = value.lastIndexOf(searchArg, value.length - 1);
        occurrence = n === (value.length - 1) - (searchArg.length - 1) && n > -1;
    } else occurrence = contains(term, value);

    return occurrence;
};

export const parseNumber = (value, decimal = ".") => {
    // Build regex to strip out everything except digits, decimal point and minus sign
    let regex = new RegExp("[^0-9-" + decimal + "]", ["g"]);
    let unformatted = parseFloat(
        ("" + value)
            // replace bracketed values with negatives
            .replace(/\((.*)\)/, "-$1")
            // strip out any cruft
            .replace(regex, "")
            // make sure decimal point is standard
            .replace(decimal, ".")
    );

    // This will fail silently
    return !isNaN(unformatted) ? unformatted : 0;
};

export const contains = (term, data, exactMatch = false, caseSensitive = false) => {
    const modifier = caseSensitive ? "g" : "gi";
    const regexp = exactMatch ?
        new RegExp("(^\\s*)" + escapeRegExp(term) + "(\\s*$)", modifier) :
        new RegExp(escapeRegExp(term), modifier);

    return regexp.test(data);
};
