import React, {useCallback, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {useFarmTitle} from "states";
import Col from "react-bootstrap/Col";
import {FarmNavigationButtonGroup} from "components";
import Row from "react-bootstrap/Row";
import {getFarmOverview, getMilkYieldFarm, getSCCFarm} from "api/rest";
import moment from "moment";
import {Card} from "react-bootstrap";
import {size} from "lodash";
import {
    Area,
    CartesianGrid,
    ComposedChart,
    Label,
    Legend,
    Line,
    LineChart,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis
} from "recharts";

const startDate = moment().subtract(2, "years");

export function Dashboard() {
    const {id} = useParams();
    const {farmTitle} = useFarmTitle();

    const [message, setMessage] = useState("");
    const [milkYieldDataset, setMilkYieldDataset] = useState([]);
    const [sccDataset, setSCCDataset] = useState([]);
    const [farmOverview, setFarmOverview] = useState({});

    const submitRequestMilkYieldDataset = useCallback((id) => {
        setMessage("");
        getMilkYieldFarm(id)
            .then((data) => setMilkYieldDataset(data.filter((row) => moment(row["monster_date"]) > startDate)))
            .catch((error) => {
                if (error.response.data !== "" && error.response.data.hasOwnProperty("message"))
                    setMessage(error.response.data.message);
                else console.log(error.toString());
            });
    }, []);

    const submitRequestSCCDataset = useCallback((id) => {
        setMessage("");
        getSCCFarm(id)
            .then((data) =>
                setSCCDataset(
                    data.filter((row) => moment(row["monster_date"]) > startDate).map((row) => {
                        row["cell_count_band"] = [row["cell_count_10th"], row["cell_count_90th"]];
                        return row;
                    })
                ))
            .catch((error) => {
                if (error.response.data !== "" && error.response.data.hasOwnProperty("message"))
                    setMessage(error.response.data.message);
                else console.log(error.toString());
            });
    }, []);

    const submitRequestFarmOverview = useCallback((id) => {
        setMessage("");
        getFarmOverview(id)
            .then((data) => setFarmOverview(data))
            .catch((error) => {
                if (error.response.data !== "" && error.response.data.hasOwnProperty("message"))
                    setMessage(error.response.data.message);
                else console.log(error.toString());
            });
    }, []);

    useEffect(() => submitRequestMilkYieldDataset(id), [id, submitRequestMilkYieldDataset]);
    useEffect(() => submitRequestSCCDataset(id), [id, submitRequestSCCDataset]);
    useEffect(() => submitRequestFarmOverview(id), [id, submitRequestFarmOverview]);

    return (
        <div className="shadow">
            <Row className="justify-content-between pt-3 pl-3 pr-3">
                <Col md="auto">
                    <h4>{farmTitle}</h4>
                </Col>
                <Col md="auto">
                    <FarmNavigationButtonGroup id={id}/>
                </Col>
            </Row>
            <Row className="pl-3 pr-3 pb-3">
                <Col md="auto">
                    <h4>Dashboard</h4>
                </Col>
            </Row>
            <Row className="pl-3 pr-3 pb-3">
                <Col>
                    {size(farmOverview) !== 0 && <LastMPRNumbers last_mpr={farmOverview["last_mpr"]}
                                                                 scc_mean={farmOverview["scc_mean"]}
                                                                 scc_mean_before={farmOverview["scc_mean_before"]}
                                                                 category_count={farmOverview["category_count"]}
                                                                 at_farm={farmOverview["at_farm"]}
                                                                 cow_age_mean={farmOverview["cow_age_mean"]}/>}
                </Col>
            </Row>
            <Row className="pl-3 pr-3 pb-3">
                <Col>
                    {size(farmOverview) !== 0 &&
                    <TodayNumbers cows_treated_with_ahv_products={farmOverview["cows_treated_with_ahv_products"]}/>}
                </Col>
            </Row>
            <Row className="pl-3 pr-3 pb-3"
                 style={(sccDataset.length !== 0 || milkYieldDataset.length !== 0) ? {minHeight: "60vh"} : {}}>
                <Col md={6}>
                    {sccDataset.length !== 0 && <SCCBandTimeChart dataset={sccDataset}/>}
                </Col>
                <Col md={6}>
                    {milkYieldDataset.length !== 0 &&
                    <SynchronizedDoubleYaxisLineTimeChart dataset={milkYieldDataset}/>}
                </Col>
            </Row>
            <Row className="justify-content-center">
                <Col md="auto">
                    <b>{message}</b>
                </Col>
            </Row>
        </div>
    );
}

function LastMPRNumbers({last_mpr, scc_mean, scc_mean_before, category_count, at_farm, cow_age_mean}) {
    return (
        <Card>
            <Card.Header>
                <span className="font-weight-bold">Last MPR ({moment(last_mpr).format("DD-MM-YY")})</span>
            </Card.Header>
            <Card.Body>
                <Row className="justify-content-between mb-3">
                    <Col md="auto">
                        <div className="font-weight-bold text-uppercase mb-1" style={{fontSize: ".7rem"}}>
                            Average cell count
                        </div>
                        <div className="d-flex">
                            <div className="h5 mb-0 mr-2 font-weight-bold">{scc_mean.toFixed(2)}</div>
                            {scc_mean_before !== null &&
                            <div className={"small font-weight-bold text-" +
                            (scc_mean < scc_mean_before ? "success" : "danger")}>
                                {scc_mean < scc_mean_before ? "" : "+"}
                                {((scc_mean - scc_mean_before) / scc_mean_before * 100).toFixed(2)}%
                            </div>}
                        </div>
                    </Col>
                    <Col md="auto">
                        <div className="font-weight-bold text-uppercase mb-1" style={{fontSize: ".7rem"}}>
                            # UDR-C
                        </div>
                        <div className="h5 mb-0 font-weight-bold">{category_count["Clear"]}</div>
                    </Col>
                    <Col md="auto">
                        <div className="font-weight-bold text-uppercase mb-1" style={{fontSize: ".7rem"}}>
                            # UDR-L
                        </div>
                        <div className="h5 mb-0 font-weight-bold">{category_count["Latent"]}</div>
                    </Col>
                    <Col md="auto">
                        <div className="font-weight-bold text-uppercase mb-1" style={{fontSize: ".7rem"}}>
                            # UDR-P
                        </div>
                        <div className="h5 mb-0 font-weight-bold">{category_count["Persistent"]}</div>
                    </Col>
                    <Col md="auto">
                        <div className="font-weight-bold text-uppercase mb-1" style={{fontSize: ".7rem"}}>
                            # UDR-R
                        </div>
                        <div className="h5 mb-0 font-weight-bold">{category_count["Persistent Chronic"]}</div>
                    </Col>
                </Row>
                <Row className="justify-content-between">
                    <Col md="auto">
                        <div className="font-weight-bold text-uppercase mb-1" style={{fontSize: ".7rem"}}>
                            Average lactating cow age
                        </div>
                        <div className="h5 mb-0 font-weight-bold">{cow_age_mean.toFixed(2)}</div>
                    </Col>
                    <Col md="auto">
                        <div className="font-weight-bold text-uppercase mb-1" style={{fontSize: ".7rem"}}>
                            # lactating
                        </div>
                        <div className="h5 mb-0 font-weight-bold">{at_farm["lactating"]}</div>
                    </Col>
                    <Col md="auto">
                        <div className="font-weight-bold text-uppercase mb-1" style={{fontSize: ".7rem"}}>
                            # dried off
                        </div>
                        <div className="h5 mb-0 font-weight-bold">{at_farm["dried_off"]}</div>
                    </Col>
                    <Col md="auto">
                        <div className="font-weight-bold text-uppercase mb-1" style={{fontSize: ".7rem"}}>
                            # calves
                        </div>
                        <div className="h5 mb-0 font-weight-bold">{at_farm["calf"]}</div>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    );
}

function TodayNumbers({cows_treated_with_ahv_products}) {
    return (
        <Card>
            <Card.Header>
                <span className="font-weight-bold">As of today ({moment().format("DD-MM-YY")})</span>
            </Card.Header>
            <Card.Body>
                <Row className="justify-content-between">
                    <Col md="auto">
                        <div className="font-weight-bold text-uppercase mb-1" style={{fontSize: ".7rem"}}>
                            Latest # Cows treated AHV products
                        </div>
                        <div className="h5 mb-0 font-weight-bold">{cows_treated_with_ahv_products}</div>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    )
}

function SCCBandTimeChart({dataset}) {
    return (
        <Card className="w-100 h-100">
            <Card.Header>
                <span className="font-weight-bold">Cell count with 10th and 90th percentile</span>
            </Card.Header>
            <Card.Body className="pl-0">
                <ResponsiveContainer>
                    <ComposedChart data={dataset} margin={{left: 20, bottom: 20}}>
                        <CartesianGrid strokeDasharray="10 10"/>
                        <Tooltip labelFormatter={(unixTime) => moment(unixTime).format("DD MMMM YYYY")}/>
                        <Legend verticalAlign="top"/>
                        <XAxis dataKey="monster_date" type="number" domain={["auto", "auto"]} scale="time"
                               tickFormatter={(unixTime) => moment(unixTime).format("MMM YYYY")}
                               allowDuplicatedCategory={false} interval={"preserveStart"}>
                            <Label value="MPR" offset={0} position="bottom"/>
                        </XAxis>
                        <YAxis scale="log" label={{value: "SCC (log)", angle: -90, position: "left"}}
                               domain={["auto", "auto"]}/>
                        <Area dataKey="cell_count_band" name="10th and 90th" stroke="darkgreen" strokeWidth={1}
                              fill="darkgreen" activeDot={false} fillOpacity={0.4}/>
                        <Line dataKey="cell_count_50th" name="Median" stroke="darkgreen" strokeWidth={1}
                              dot={{r: 1.5, strokeWidth: 1}}/>
                    </ComposedChart>
                </ResponsiveContainer>
            </Card.Body>
        </Card>
    );
}

function SynchronizedDoubleYaxisLineTimeChart({dataset}) {
    return (
        <Card className="w-100 h-100">
            <Card.Header>
                <span className="font-weight-bold">Milk properties</span>
            </Card.Header>
            <Card.Body className="pl-0">
                <ResponsiveContainer height="50%">
                    <LineChart margin={{left: 20, right: 20}} data={dataset}>
                        <CartesianGrid strokeDasharray="10 10"/>
                        <Legend verticalAlign="top"/>
                        <XAxis dataKey="monster_date" type="number" domain={["auto", "auto"]} scale="time"
                               tickFormatter={(unixTime) => moment(unixTime).format("MMM YYYY")}
                               allowDuplicatedCategory={false} interval={"preserveStart"}/>
                        <YAxis yAxisId="left" label={{value: "Milk yield (kg)", angle: -90, position: "left"}}
                               domain={["auto", "auto"]}/>
                        <Line type="monotone" yAxisId="left" dataKey="milk_yield" name="Milk yield" stroke="darkgreen"
                              strokeWidth={2} dot={{r: 1.5, strokeWidth: 1}} activeDot={{r: 5}}/>
                        <YAxis yAxisId="right" label={{value: "Fat %", angle: 90, position: "right"}}
                               domain={["auto", "auto"]} orientation="right"/>
                        <Line type="monotone" yAxisId="right" dataKey="fat" name="Fat" stroke="blue" strokeWidth={2}
                              dot={{r: 1.5, strokeWidth: 1}} activeDot={{r: 5}}/>
                        <Tooltip labelFormatter={(unixTime) => moment(unixTime).format("DD MMMM YYYY")}/>
                    </LineChart>
                </ResponsiveContainer>
                <ResponsiveContainer height="50%">
                    <LineChart margin={{left: 20, right: 20, bottom: 20}} data={dataset}>
                        <CartesianGrid strokeDasharray="10 10"/>
                        <Legend verticalAlign="top"/>
                        <XAxis dataKey="monster_date" type="number" domain={["auto", "auto"]} scale="time"
                               tickFormatter={(unixTime) => moment(unixTime).format("MMM YYYY")}
                               allowDuplicatedCategory={false} interval={"preserveStart"}>
                            <Label value="MPR" offset={0} position="bottom"/>
                        </XAxis>
                        <YAxis yAxisId="left" label={{value: "Urea (mg/100g)", angle: -90, position: "left"}}
                               domain={["auto", "auto"]}/>
                        <Line type="monotone" yAxisId="left" dataKey="urea" name="Urea" stroke="darkgreen"
                              strokeWidth={2} dot={{r: 1.5, strokeWidth: 1}} activeDot={{r: 5}}/>
                        <YAxis yAxisId="right" label={{value: "Protein %", angle: 90, position: "right"}}
                               domain={["auto", "auto"]} orientation="right"/>
                        <Line type="monotone" yAxisId="right" dataKey="protein" name="Protein" stroke="blue"
                              strokeWidth={2} dot={{r: 1.5, strokeWidth: 1}} activeDot={{r: 5}}/>
                        <Tooltip labelFormatter={(unixTime) => moment(unixTime).format("DD MMMM YYYY")}/>
                    </LineChart>
                </ResponsiveContainer>
            </Card.Body>
        </Card>
    );
}