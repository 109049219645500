import React, {useState} from "react";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import {sendFarmCAMail} from "api/rest";

export function MailButtonCA({farm_id}) {
    const [disableMail, setDisableMail] = useState(false);

    const handleOnClickSendCAMail = () => {
        setDisableMail(true);
        sendFarmCAMail(farm_id, ["refresh=True", "expanded=False", "treatment_update=True"])
            .then(() => {})
            .catch((error) => console.log(error.toString()));
    };
    const handleOnClickSendExpandedCAMail = () => {
        setDisableMail(true);
        sendFarmCAMail(farm_id, ["refresh=True", "expanded=True", "treatment_update=True"])
            .then(() => {})
            .catch((error) => console.log(error.toString()));
    };

    return (
        <ButtonGroup>
            <Button variant="outline-primary" size="sm" className="mr-2" onClick={handleOnClickSendCAMail}
                    disabled={disableMail}>Send CA mail</Button>
            <Button variant="outline-primary" size="sm" onClick={handleOnClickSendExpandedCAMail}
                    disabled={disableMail}>Expanded mail</Button>
        </ButtonGroup>
    );
}