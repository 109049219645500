import React, {useCallback, useEffect, useState} from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {getFarms, getLastMprDates} from "api/rest";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {BasicTable} from "components";
import {isMobileOnly} from "react-device-detect";
import {useHistory} from "react-router-dom";
import {TextFilterDebounced} from "components/Filter";
import moment from "moment";

export function Farms() {
    const [farms, setFarms] = useState([]);
    const [filteredFarms, setFilteredFarms] = useState([]);
    const [lastMprDates, setLastMprDates] = useState({});

    const submitRequestFarms = () => {
        getFarms()
            .then((data) => {
                const results = data.hasOwnProperty("results") ? data.results : data;
                getLastMprDates()
                    .then(data => setLastMprDates(data))
                    .catch(error => console.log(error.toString()));
                setFarms(results);
                setFilteredFarms(results);
            }).catch((error) => {
                console.log(error)
                console.log(error.toString())
        });
    };

    const filterFarms = (filterValue) => {
        if (farms.length === 0) return;
        if (filterValue === "") setFilteredFarms(farms);
        else {
            filterValue = filterValue.toLowerCase();

            setFilteredFarms(farms.filter((farm) =>
                farm["location"]["identifier_type_id"].toLowerCase().includes(filterValue) ||
                farm["surname"].toLowerCase().includes(filterValue) ||
                farm["city"].toLowerCase().includes(filterValue) ||
                farm["representatives"].some((representative) =>
                    representative["name"].toLowerCase().includes(filterValue))
            ));
        }
    };

    useEffect(() => submitRequestFarms(), []);

    return (
        <div className="shadow">
            <Row className="justify-content-between pt-3 pl-3 pr-3 pb-2">
                <Col md="auto">
                    <h4>Farms</h4>
                </Col>
                <Col md="4">
                    <TextFilterDebounced value="" onChange={filterFarms} debounceTime={500} placeholder="Search"/>
                </Col>
            </Row>
            <Row className="pl-3 pr-3">
                <Col>
                    <FarmTable farms={filteredFarms} lastMprDates={lastMprDates}/>
                </Col>
            </Row>
        </div>
    );
}

function FarmTable({farms, lastMprDates}) {
    const history = useHistory();

    const handleOnClickFarmDetails = useCallback((location_id) => {
        if (isMobileOnly) history.push("/farms/" + location_id + "/treatments/add")
        else history.push("/farms/" + location_id + "/");
    }, [history]);

    const columns = React.useMemo(() => [
        {
            id: "farm_details", accessor: "location.id",
            Cell: ({value}) => <FontAwesomeIcon icon="arrow-right" onClick={() => handleOnClickFarmDetails(value)}/>,
            width: 20
        },
        {
            Header: "Location", id: "location",
            accessor: row => "[" + row.location.scheme + "] " + row.location.identifier_type_id,
            sortType: "basic", width: 160
        },
        {Header: "Surname", accessor: "surname", width: 220},
        {Header: "Initials", accessor: "initials", width: 150},
        {Header: "Postcode", accessor: "postcode", width: 80},
        {Header: "City", accessor: "city", width: 220},
        {Header: "Country", accessor: "country", width: 100},
        {Header: "Connection", accessor: "connection", width: 120},
        {
            Header: "Representatives", id: "representatives",
            accessor: row => row.representatives.map((r) => r.name).join(", "), width: 150
        },
        {
            Header: "MPR", id: "last_mpr_date", accessor: row => lastMprDates[row.location.id],
            Cell: ({value}) => value ? moment(value).format("DD-MM-YY") : "",
            width: 100
        }
    ], [handleOnClickFarmDetails, lastMprDates]);

    return (
        <BasicTable columns={columns} data={farms} useBlock={true}/>
    );
}