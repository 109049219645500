import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useParams} from "react-router-dom";
import {useFarmTitle} from "states";
import {getAggregatedResearch} from "api/rest";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import {FarmNavigationButtonGroup} from "components";
import moment from "moment";
import {startCase} from "lodash";
import {NumberInputGroup, SelectInputGroup} from "components/InputGroup";
import {SeriesLineTimeChart} from "components/LineTimeChart";

export function AggregatedResearch() {
    const {id} = useParams();
    const {farmTitle} = useFarmTitle();

    const [message, setMessage] = useState("");
    const [dataset, setDataset] = useState([]);
    const [yearTo, setYearTo] = useState(moment().year());
    const [yearFrom, setYearFrom] = useState(moment().year() - 5);
    const [yAxis, setYAxis] = useState("net_gain");

    const submitRequestDataset = useCallback((id) => {
        setMessage("");
        getAggregatedResearch(id)
            .then((data) => setDataset(data))
            .catch((error) => {
                if (error.response.data !== "" && error.response.data.hasOwnProperty("message"))
                    setMessage(error.response.data.message);
                else console.log(error.toString());
            });
    }, []);

    const yAxisOptions = useMemo(() =>
            dataset.length === 0 ? [] :
                Object.keys(dataset[0])
                    .filter((column) => column !== "event_date_time")
                    .map((column) => ({value: column, label: startCase(column)}))
        , [dataset]);

    useEffect(() => submitRequestDataset(id), [id, submitRequestDataset]);

    return (
        <div className="shadow">
            <Row className="justify-content-between pt-3 pl-3 pr-3">
                <Col md="auto">
                    <h4>{farmTitle}</h4>
                </Col>
                <Col md="auto">
                    <FarmNavigationButtonGroup id={id}/>
                </Col>
            </Row>
            <Row className="pl-3 pr-3 pb-3">
                <Col md="auto">
                    <h4>Aggregated Research</h4>
                </Col>
            </Row>
            {
                yAxisOptions.length !== 0 &&
                <InputFilters
                    yearFrom={yearFrom} yearFromOnChange={({target}) => setYearFrom(target.value)}
                    yearTo={yearTo} yearToOnChange={({target}) => setYearTo(target.value)}
                    yAxis={yAxis} yAxisOptions={yAxisOptions}
                    yAxisOnChange={(chosen) => setYAxis(chosen.length === 0 ? "" : chosen[0].value)}
                />
            }
            <Row className="pl-3 pr-3 pb-3" style={dataset.length !== 0 ? {minHeight: "70vh"} : {}}>
                <Col>
                    {
                        dataset.length !== 0 &&
                        <FarmAnalysisChart dataset={dataset} yearFrom={yearFrom} yearTo={yearTo} yAxis={yAxis}/>
                    }
                </Col>
            </Row>
            <Row className="justify-content-center">
                <Col md="auto">
                    <b>{message}</b>
                </Col>
            </Row>
        </div>
    );
}

function InputFilters({yearFrom, yearFromOnChange, yearTo, yearToOnChange, yAxis, yAxisOptions, yAxisOnChange}) {
    return (
        <Form.Row className="pl-3 pr-3 pb-3 justify-content-center">
            <Form.Group as={Col} md="auto">
                <NumberInputGroup
                    prependLabel="Year from"
                    defaultValue={yearFrom}
                    onChange={yearFromOnChange}
                />
            </Form.Group>
            <Form.Group as={Col} md="auto">
                <NumberInputGroup
                    prependLabel="Year to"
                    defaultValue={yearTo}
                    onChange={yearToOnChange}
                />
            </Form.Group>
            <Col md="auto">
                <SelectInputGroup
                    prependLabel="Y-axis"
                    defaultValue={[{value: yAxis, label: startCase(yAxis)}]}
                    onChange={yAxisOnChange}
                    options={yAxisOptions}
                />
            </Col>
        </Form.Row>
    );
}

function FarmAnalysisChart({dataset, yearFrom, yearTo, yAxis}) {
    const series = useMemo(() =>
        dataset.reduce((result, row) => {
            const date = moment(row["event_date_time"]);
            if (date.year() < yearFrom || date.year() > yearTo) return result;

            const year = date.year().toString()
            if (!result.hasOwnProperty(year)) result[year] = [];

            const fake_date = date.clone().year(2020).valueOf();
            result[year].push({fake_date: fake_date, yAxis: row[yAxis]})

            return result;
        }, {}), [dataset, yearFrom, yearTo, yAxis])

    return (
        <SeriesLineTimeChart
            series={series}
            xAxisKey="fake_date"
            yAxisKey="yAxis"
            xAxisLabel="MPR"
            yAxisLabel={startCase(yAxis)}
            xAxisFormatter={(unixTime) => moment(unixTime).format("MMM")}
            tooltipXAxisFormatter={(unixTime) => moment(unixTime).format("DD MMMM")}
        />
    );
}