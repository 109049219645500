import React, {useCallback, useEffect, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import {FarmNavigationButtonGroup, FarmTreatmentTable} from "components";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useFarmTitle} from "states";
import {getFarmTreatments} from "api/rest";
import {MailButtonCA} from "components/MailButtonCA";

function FarmTreatmentsPage() {
    const history = useHistory();
    const {id} = useParams();
    const {farmTitle} = useFarmTitle();

    const [treatments, setTreatments] = useState([]);

    const getAndSetTreatments = useCallback((id) => {
        getFarmTreatments(id, ["ordering=-created_at", "limit=10000"])
            .then((data) => setTreatments(data.hasOwnProperty("results") ? data.results : data))
            .catch((error) => console.log(error.toString()));
    }, []);

    const handleOnClickAddTreatment = () => history.push("/farms/" + id + "/treatments/add");

    useEffect(() => getAndSetTreatments(id), [id, getAndSetTreatments]);

    return (
        <div className="shadow">
            <Row className="justify-content-between pt-3 pl-3 pr-3">
                <Col md="auto">
                    <h4>{farmTitle}</h4>
                </Col>
                <Col md="auto">
                    <FarmNavigationButtonGroup id={id}/>
                </Col>
            </Row>
            <Row className="pl-3 pr-3 pb-3">
                <Col md="auto">
                    <h4>Treatments</h4>
                </Col>
                <Col md="auto" className="mr-auto">
                    <MailButtonCA farm_id={id}/>
                </Col>
                <Col md="auto" className="justify-content-end pt-1">
                    <Button onClick={handleOnClickAddTreatment}><FontAwesomeIcon icon="plus"/></Button>
                </Col>
            </Row>
            <Row className="pl-3 pr-3">
                <Col>
                    <FarmTreatmentTable id={id} treatments={treatments}
                                        refreshTreatments={() => getAndSetTreatments(id)}/>
                </Col>
            </Row>
        </div>
    );
}

export default FarmTreatmentsPage;