import React, {useCallback, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {FarmNavigationButtonGroup, FarmTreatmentTable, TreatmentForm} from "components";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import {useFarmTitle} from "states";
import {getFarmTreatments} from "../api/rest";
import {isMobileOnly} from "react-device-detect";
import {MailButtonCA} from "../components/MailButtonCA";

function AddTreatmentPage() {
    const {id} = useParams();
    const {farmTitle} = useFarmTitle();

    const [treatments, setTreatments] = useState([]);

    const getAndSetTreatments = useCallback((id) => {
        getFarmTreatments(id, ["ordering=-created_at", "limit=5"])
            .then((data) => setTreatments(data.hasOwnProperty("results") ? data.results : data))
            .catch((error) => console.log(error.toString()));
    }, []);

    useEffect(() => getAndSetTreatments(id), [id, getAndSetTreatments]);

    return (
        <div className="shadow">
            <Row className="justify-content-between pt-3 pl-3 pr-3">
                <Col md="auto">
                    <h4>{farmTitle}</h4>
                </Col>
                <Col md="auto">
                    <FarmNavigationButtonGroup id={id}/>
                </Col>
            </Row>
            <Row className="pl-3 pr-3 pb-3">
                <Col md="auto">
                    <h4>Add treatment</h4>
                </Col>
            </Row>
            {
                isMobileOnly &&
                <Row className="pl-3 pr-3 pb-3">
                    <Col md="auto">
                        <MailButtonCA farm_id={id}/>
                    </Col>
                </Row>
            }
            <Row className="pl-3 pr-3 pb-3">
                <Col>
                    <TreatmentForm id={id} refreshTreatments={() => getAndSetTreatments(id)}/>
                </Col>
            </Row>
            <Row className="pl-3 pr-3">
                <Col>
                    <h4 className="text-center">Last 5 treatments</h4>
                    <FarmTreatmentTable id={id} treatments={treatments}
                                        refreshTreatments={() => getAndSetTreatments(id)}/>
                </Col>
            </Row>
        </div>
    );
}

export default AddTreatmentPage;